import { html, css, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { classMap } from "lit-html/directives/class-map";

@customElement("mpl-modal-dialog")
export class ModalDialog extends LitElement {
  // ---------------- styles ---------------- \\

  static styles = css`
    .wrapper {
      opacity: 0;
      position: fixed;
      z-index: 10;
      transition: opacity 0.25s ease-in;
    }
    .wrapper:not(.open) {
      visibility: hidden;
    }
    .wrapper.open {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      opacity: 1;
      visibility: visible;
    }
    .overlay {
      background: rgba(0, 0, 0, 0.8);
      height: 100%;
      width: 100%;
      position: relative;
    }
    .dialog-wrapper {
      background: var(--color-background);
      border-radius: var(--border-radius-s);
      max-width: 400px;
      width: 80%;
      padding: var(--spacing-xl);
      position: absolute;
      margin: 0 auto;
      max-height: 80vh;
      overflow: auto;
    }
    .dialog-wrapper h1 {
      text-align: center;
      margin: 0px 0px var(--spacing-l);
      font-size: 1.4rem;
      font-weight: 700;
      color: var(--color-text);
    }
  `;

  open = false;
  close = () => {};

  static get properties() {
    return {
      open: { type: Boolean },
      title: { type: String },
    };
  }

  handleClick() {
    this.close();
  }

  render() {
    return html`
      <div class="${classMap({ wrapper: true, open: this.open })}">
        <div class="overlay" @click="${this.close}"></div>
        <div class="dialog-wrapper">
          <h1>${this.title}</h1>
          <slot></slot>
        </div>
      </div>
    `;
  }
}
