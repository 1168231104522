import "normalize.css";

import { html, css, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import "./components/city-input";
import "./components/ui/autocomplete";
import "./components/guess-list";
import "./components/guess";
import "./components/header";
import "./components/game";
import "./components/modals";
import "./components/modal-dialog";
import "./components/stats";
import "./components/ftux";
import "./components/correct-city";
import "./components/ui/button";
import "./components/ui/switch";

import "./modals/welcome-modal";
import "./modals/stats-modal";
import "./modals/win-modal";
import "./modals/fail-modal";
import "./modals/settings-modal";

import { modalEvents } from "./utils/events/modalEventBus";
import { storage } from "./utils/storage";
import { isToday } from "./utils/date";
import { getCorrectCity } from "./utils/utils";

@customElement("mpl-app")
export class App extends LitElement {
  static styles = css`
    .app-wrapper {
      position: absolute;
      width: 100%;
    }
    .game {
      max-width: var(--app-width);
      width: 100%;
      margin: 65px auto 0;
      box-sizing: border-box;
      padding: var(--spacing-xl);
    }
    .footer {
      margin-top: var(--spacing-xxl);
      display: flex;
      justify-content: space-between;
      color: var(--color-subtext);
    }
    .mobile-links a {
      color: var(--color-text);
      text-decoration: none;
    }
  `;

  private _hasNeverPlayed() {
    const liveState = storage.state.live;
    const previousGamesState = storage.state.previousGames;

    return liveState.guessList.length === 0 && previousGamesState.length === 0;
  }

  private _handleModalOpen() {
    const previousGamesState = storage.state.previousGames;
    const lastGuess = previousGamesState[previousGamesState.length - 1];

    // if never played - return welcome modal
    if (this._hasNeverPlayed()) {
      modalEvents.showModal("welcome");
    }
    // if played today - check the result
    else if (lastGuess && isToday(new Date(lastGuess.timestamp))) {
      if (lastGuess.result === "win") {
        modalEvents.showModal("win", {
          correctCity: getCorrectCity(),
          guessList: storage.state.live.guessList,
        });
      } else {
        modalEvents.showModal("fail", {
          correctCity: getCorrectCity(),
          guessList: storage.state.live.guessList,
        });
      }
    }
  }

  connectedCallback() {
    super.connectedCallback();

    // Set theme
    if (storage.state.settings.darkMode) {
      document.getElementsByTagName("body")[0].classList.add("dark-mode");
    }

    storage.subscribe((state) => {
      if (state.settings.darkMode) {
        document.getElementsByTagName("body")[0].classList.add("dark-mode");
      } else {
        document.getElementsByTagName("body")[0].classList.remove("dark-mode");
      }

      this.requestUpdate();
    }, []);

    setTimeout(() => {
      this._handleModalOpen();
    }, 0);
  }

  render() {
    const neverPlayed = this._hasNeverPlayed();

    return html`
      <div>
        <div class="app-wrapper">
          <mpl-header></mpl-header>
          <div class="game">
            ${neverPlayed ? html`<mpl-ftux></mpl-ftux>` : null}
            <mpl-game></mpl-game>
            <div class="footer">
              mapplegame 2023 ©
              <div class="mobile-links">
                <a href="https://apps.apple.com/ba/app/mapplegame/id1672868473"
                  > App Store</a
                >
              </div>
            </div>
          </div>
        </div>
        <mpl-modals />
      </div>
    `;
  }
}
