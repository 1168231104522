import { html, css, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { classMap } from "lit-html/directives/class-map";
import { City, Guess } from "../types";
import { storage } from "../utils/storage";

@customElement("mpl-guess")
export class GuessItem extends LitElement {
  static styles = css`
    .wrapper {
      display: flex;
    }

    .number {
      width: 44px;
      height: 44px;
      font-size: 1.1rem;
      border-radius: 50%;
      background: var(--color-invalid);
      color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .number.close {
      background: var(--color-warning);
    }
    .number.correct {
      background: var(--color-valid);
    }
    .number.placeholder {
      background-color: var(--color-border);
    }

    .details {
      margin-left: var(--spacing-m);
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 130%;
    }

    .city {
      color: var(--color-text);
      font-size: 1.2rem;
      display: flex;
    }

    .flag {
      font-size: 1.5rem;
      margin-left: var(--spacing-s);
    }

    .distance {
      color: var(--color-subtext);
      font-size: 1rem;
    }

    .region-guess {
      margin-left: var(--spacing-s);
      font-size: 1rem;
      color: var(--color-valid);
    }

    /* placeholder */
    .city.placeholder {
      width: 130px;
      height: 20px;
      background-color: var(--color-border);
    }

    .distance.placeholder {
      width: 190px;
      height: 15px;
      margin-top: var(--spacing-xs);
      background-color: var(--color-border);
    }
  `;

  connectedCallback(): void {
    super.connectedCallback();
    storage.subscribe(() => {
      this.requestUpdate();
    }, ["update-settings"]);
  }

  static get properties() {
    return {
      index: { type: Number },
    };
  }

  index = 0;
  city: Guess;
  correctCity: City;
  placeholder: boolean = false;

  _distance() {
    if (storage.state.settings.unit === "kilometers") {
      return `${Math.round(this.city.distance)} kilometers`;
    }

    return `${Math.round(this.city.distance * 0.621371)} miles`;
  }

  render() {
    const showRegionGuessed =
      !storage.state.settings.hardMode &&
      this.city?.region === this.correctCity?.region;

    return html`<div class="wrapper">
      <div
        class="${classMap({
          number: true,
          correct: this.city?.isCorrect,
          close: showRegionGuessed,
          placeholder: this.placeholder,
        })}"
      >
        #${this.index}
      </div>
      <div class="details">
        <div class="${classMap({ city: true, placeholder: this.placeholder })}">
          ${!this.placeholder
            ? html`${this.city.city}
                <div class="flag">${this.city.unicodeFlag}</div>`
            : null}
        </div>
        <div
          class="${classMap({ distance: true, placeholder: this.placeholder })}"
        >
          ${!this.placeholder
            ? this.city.isCorrect
              ? "Correct!"
              : this._distance()
            : null}
        </div>
      </div>
    </div>`;
  }
}
