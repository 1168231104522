import { html, css, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

@customElement("mpl-ftux")
export class Ftux extends LitElement {
  static styles = css`
    .wrapper {
      background-color: var(--color-header-background);
      padding: var(--spacing-l);
      border-radius: 10px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
      position: relative;
      overflow: hidden;
      margin-bottom: var(--spacing-xl);
    }

    .title {
      font-size: 1.4rem;
      margin-bottom: var(--spacing-s);
      font-weight: bold;
      color: var(--color-text);
    }

    .description {
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: var(--color-subtext);
    }

    .graphic {
      position: absolute;
      width: 100%;
      right: 0;
      left: 0;
      bottom: -10px;
      filter: grayscale(1);
      opacity: 0.08;
    }
  `;

  render() {
    return html`<div class="wrapper">
      <div class="info">
        <div class="title">Make your first guess!</div>
        <div class="description">
          Give it a try with your favorite capital in the world! The result will
          give you an idea where the city we're looking for could be located.
          <br /><br />With more cities you try you can draw circles in your head
          and figure out where they overlap, and that's your answer!
        </div>
      </div>

      <div class="graphic">
        <svg
          viewbox="0 0 155 97"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#a)">
            <path
              d="M41.901 23.623c0 9.306 14.662 36.751 23.069 51.722a6.55 6.55 0 0 0 2.414 2.451 6.618 6.618 0 0 0 6.665 0 6.55 6.55 0 0 0 2.414-2.451c8.407-14.971 23.068-42.416 23.068-51.722C99.531 10.576 86.631 0 70.716 0 54.802 0 41.902 10.576 41.902 23.623Z"
              fill="#544CBB"
            />
            <path
              d="M70.581 38.924c-8.667 0-15.693-6.97-15.693-15.57 0-8.598 7.026-15.57 15.693-15.57s15.693 6.971 15.693 15.57c0 8.6-7.026 15.57-15.693 15.57Z"
              fill="#fff"
            />
            <path
              d="M136.066 37.442c-5.191 0-9.4-4.175-9.4-9.326 0-5.15 4.209-9.325 9.4-9.325 5.191 0 9.399 4.175 9.399 9.325 0 5.15-4.208 9.326-9.399 9.326Z"
              fill="#FF6584"
            />
            <path
              opacity=".4"
              d="M137.979 36.41c-.478-2.925 1.524-5.681 4.472-6.156 2.948-.474 5.725 1.512 6.204 4.437.478 2.924-1.524 5.68-4.472 6.155-2.948.474-5.725-1.512-6.204-4.437Z"
              fill="#544CBB"
            />
            <path
              d="M16.805 63.226c42.584-15.838 94.521-.398 124.507 31.187.171.177.341.357.506.537h.457c-.157-.18-.319-.36-.479-.537C111.188 60.426 59.94 46.88 16.805 63.226ZM5.168 68.479a102.067 102.067 0 0 1 11.637-5.253 92.698 92.698 0 0 0-11.637 5.253Z"
              fill="#3F3D56"
            />
            <path
              opacity=".4"
              d="M28.474 52.677c-3.276 0-5.93-2.634-5.93-5.884 0-3.25 2.654-5.884 5.93-5.884 3.275 0 5.93 2.634 5.93 5.884 0 3.25-2.655 5.884-5.93 5.884Z"
              fill="#544CBB"
            />
            <path
              d="M27.814 92.054c-.459 1.68-2.029 2.722-2.029 2.722s-.826-1.685-.368-3.366c.458-1.68 2.028-2.722 2.028-2.722s.827 1.685.369 3.366Z"
              fill="#3F3D56"
            />
            <path
              d="M27.14 91.692c-1.258 1.215-1.411 3.082-1.411 3.082s1.885-.102 3.143-1.317c1.258-1.214 1.411-3.081 1.411-3.081s-1.885.102-3.143 1.316Z"
              fill="#544CBB"
            />
            <path
              d="M154.09 68.682v2.593l.131.14v-2.76c-.044.008-.087.019-.131.027Z"
              fill="#3F3D56"
            />
            <path
              d="M145.763 94.412c.038.061.078.123.119.185h8.339V68.81l-.13-.13-1.107 1.249v-1.825h-3.517v5.792l-3.552 4.003-.033.038a20.633 20.633 0 0 0-.127.408 47.368 47.368 0 0 0-1.31 5.151c-.773 4.104-.657 7.868 1.318 10.915Z"
              fill="#CCC"
            />
            <path
              d="M151.293 82.935h2.239v1.851h-2.239v-1.85ZM151.293 86.267h2.239v1.851h-2.239v-1.85ZM82.33 79.418l1.12-1.85-10.448-8.885c-7.724-3.689-17.034-2.625-26.865 0v2.59l9.701 10.367 11.194 2.22 15.298-4.442Z"
              fill="#3F3D56"
            />
            <path
              d="M41.512 73.897c-1.855-2.048-2.113-3.995 0-5.793h3.517v1.827l1.108-1.248 8.955 8.884 1.492 1.481 1.866 7.404-3.358 8.145H37.928v-16.66l3.584-4.04Z"
              fill="#CCC"
            />
            <path
              d="M83.45 77.567H55.091v17.03H83.45c3.43-3.683 1.648-10.395 0-17.03Z"
              fill="#F2F2F2"
            />
            <path d="M59.01 82.935h5.97v5.183h-5.97v-5.183Z" fill="#3F3D56" />
            <path d="M67.405 82.75h5.224v11.847h-5.224V82.75Z" fill="#CCC" />
            <path
              d="M78.599 84.601v-1.85h2.238v1.85H78.6ZM78.785 86.082h2.052v1.851H78.6v-1.851h.186ZM74.868 82.75h2.238v1.851h-2.238v-1.85ZM74.868 86.082h2.238v1.851h-2.238v-1.851ZM47.07 84.786v-1.85h2.239v1.85H47.07ZM47.256 86.267h2.053v1.851h-2.24v-1.85h.187ZM43.338 82.935h2.24v1.851h-2.24v-1.85ZM43.338 86.267h2.24v1.851h-2.24v-1.85ZM132.823 48.322l1.866-1.481-10.448-9.255H97.749v8.144l17.164 11.477 17.91-8.885Z"
              fill="#3F3D56"
            />
            <path
              d="M92.92 42.777v-5.006h3.516v1.226l1.313-1.411 8.582 9.255 3.358 2.221 5.207 37.744-7.852 7.79h-18.25c-2.796-13.955-5.013-28.291 0-47.385l4.125-4.434Z"
              fill="#CCC"
            />
            <path
              d="M134.689 46.84h-28.358v47.757h28.358c3.103-18.553 4.618-35.758 0-47.756Z"
              fill="#F2F2F2"
            />
            <path d="M118.458 82.935h5.224v11.662h-5.224V82.934Z" fill="#CCC" />
            <path
              d="M129.278 86.267v-1.85h2.239v1.85h-2.239ZM129.465 87.748h2.052v1.851h-2.239v-1.851h.187ZM125.547 84.416h2.239v1.851h-2.239v-1.85ZM114.167 87.748h2.052v1.851h-2.239v-1.851h.187ZM110.249 84.416h2.239v1.851h-2.239v-1.85ZM110.249 87.748h2.239v1.85h-2.239v-1.85ZM98.309 49.247v-1.85h2.239v1.85h-2.239ZM98.495 50.728h2.053v1.851h-2.239v-1.85h.186ZM94.578 47.396h2.238v1.851h-2.238v-1.85ZM94.578 50.728h2.238v1.851h-2.238v-1.851ZM98.309 59.613v-1.851h2.239v1.851h-2.239ZM98.495 61.094h2.053v1.85h-2.239v-1.85h.186ZM94.578 57.762h2.238v1.85h-2.238v-1.85ZM94.578 61.093h2.238v1.851h-2.238v-1.85ZM98.309 69.978v-1.85h2.239v1.85h-2.239ZM98.495 71.46h2.053v1.85h-2.239v-1.85h.186ZM94.578 68.127h2.238v1.851h-2.238v-1.85ZM94.578 71.46h2.238v1.85h-2.238v-1.85ZM98.309 80.344v-1.851h2.239v1.851h-2.239ZM98.495 81.825h2.053v1.85h-2.239v-1.85h.186ZM94.578 78.493h2.238v1.85h-2.238v-1.85ZM94.578 81.825h2.238v1.85h-2.238v-1.85ZM131.517 49.247h-21.641v4.443c6.937-1.9 14.17-1.764 21.641 0v-4.443ZM131.517 58.132c-6.754 1.974-13.968 1.974-21.641 0v4.442h21.641v-4.442ZM131.517 67.017h-21.641v4.442c7.375-2.337 14.597-2.447 21.641 0v-4.442ZM131.517 75.901c-7.058 2.7-14.278 2.587-21.641 0v4.443h21.641v-4.442ZM15.206 68.184H0v7.269l1.358.422 4.519 1.399 7.088-1.63 7.081-1.627 3.74-.86 1.866-.685c-1.553-2.114-4.378-3.777-10.446-4.288Z"
              fill="#3F3D56"
            />
            <path
              d="M0 72.472v22.125h25.652l.022-.04v-.001a31.04 31.04 0 0 0 1.099-2.438c2.691-6.732 2.4-13.286-1.12-19.646H0Z"
              fill="#F2F2F2"
            />
            <path d="M9.421 82.935h5.224v11.662H9.42V82.934Z" fill="#CCC" />
            <path
              d="M20.242 86.267v-1.85h2.238v1.85h-2.238ZM20.428 87.748h2.052v1.851h-2.238v-1.851h.186ZM16.51 84.416h2.24v1.851h-2.24v-1.85ZM16.51 87.748h2.24v1.85h-2.24v-1.85ZM4.944 86.267v-1.85h2.238v1.85H4.944ZM5.13 87.748h2.052v1.851H4.944v-1.851h.186ZM1.212 84.416h2.239v1.851H1.212v-1.85ZM1.212 87.748h2.239v1.85H1.212v-1.85ZM20.045 59.594H16.83c-3.171 0-6.213 1.25-8.456 3.475a11.819 11.819 0 0 0-3.503 8.39h15.175V59.594ZM117.448 27.38h-3.216c-3.172 0-6.214 1.251-8.456 3.476a11.818 11.818 0 0 0-3.503 8.39h15.175V27.381ZM66.041 57.178h-3.216c-3.172 0-6.214 1.25-8.456 3.475a11.818 11.818 0 0 0-3.503 8.39H66.04V57.178ZM4.87 75.901h15.175v4.443H4.87v-4.442ZM139.997 73.205h.541v21.207h-.541V73.205Z"
              fill="#3F3D56"
            />
            <path
              d="M135.003 75.907c-.034 7.416 5.224 13.452 5.224 13.452s6.734-6.116 5.349-13.403c-1.394-7.329-5.736-13.42-5.224-13.451 0 0-5.315 5.987-5.349 13.402Z"
              fill="#544CBB"
            />
            <path d="M85.885 73.205h.54v21.207h-.54V73.205Z" fill="#3F3D56" />
            <path
              d="M80.89 75.907c-.487 7.4 5.225 13.452 5.225 13.452s5.314-5.988 5.348-13.403c.035-7.416-5.224-13.451-5.224-13.451.513 2.032-4.954 7.415-5.349 13.402Z"
              fill="#544CBB"
            />
            <path
              d="m121.041 38.733-23.505-1.141-9.86 8.166 1.743 1.561 15.614-.349 16.008-8.237ZM144.494 76.628l1.266 1.135.155.137.321.29 7.985-.177v-9.358c.26-.056.519-.11.779-.161l-.647-.032-.132.11-1.237 1.022-3.517 2.916-3.929 3.253-1.044.865ZM69.634 69.603l-23.505-1.14-9.86 8.165 1.743 1.562 15.614-.349 16.008-8.237Z"
              fill="#3F3D56"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path
                fill="#fff"
                transform="matrix(-1 0 0 1 155 0)"
                d="M0 0h155v97H0z"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>`;
  }
}
