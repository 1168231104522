import { html, css, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import exampleList from "../example-guess-list.json";
import { INTRO_MODAL } from "../constants";

@customElement("mpl-welcome-modal")
export class WelcomeModal extends LitElement {
  // ---------------- styles ---------------- \\

  static styles = css`
    .description {
      margin-bottom: var(--spacing-m);

      color: var(--color-subtext);
    }
    .modal-footer {
      margin-top: var(--spacing-xxl);
    }
    .text-center {
      text-align: center;
    }
    .list {
      margin-top: var(--spacing-xl);
    }
  `;

  open = false;
  close = () => {};

  static get properties() {
    return {
      open: { type: Boolean },
    };
  }

  render() {
    return html`<mpl-modal-dialog
      title=${INTRO_MODAL.title}
      .open=${this.open}
      .close=${this.close}
    >
      <div class="description">${INTRO_MODAL.description}</div>
      <div class="description">${INTRO_MODAL.description_1}</div>
      <div class="description">${INTRO_MODAL.description_2}</div>
      <div class="list">
        <mpl-guess-list
          .list=${exampleList}
          .correctCity=${exampleList[exampleList.length - 1]}
          .isGameFinished=${true}
          reduceGap
        ></mpl-guess-list>
      </div>
      <div class="modal-footer">
        <mpl-button @click="${this.close}">
          ${INTRO_MODAL.buttonText}
        </mpl-button>
      </div></mpl-modal-dialog
    >`;
  }
}
