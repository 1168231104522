import { html, css, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { Guess } from "../types";
import { storage } from "../utils/storage";

@customElement("mpl-settings-modal")
export class SettingsModal extends LitElement {
  // ---------------- styles ---------------- \\

  static styles = css`
    .settings-row {
      margin-bottom: var(--spacing-m);
      display: flex;
      justify-content: space-between;
      padding-bottom: var(--spacing-m);
      border-bottom: 1px solid var(--color-border);
    }

    .settings-row:last-child {
      border-bottom: none;
    }

    .settings-row-info-title {
      font-size: 1.2rem;
      margin-bottom: var(--spacing-xs);
      color: var(--color-text);
    }

    .settings-row-info-description {
      font-size: 0.8rem;
      color: var(--color-subtext);
    }
  `;

  open = false;
  close = () => {};
  guessList: Guess[] = [];

  static get properties() {
    return {
      open: { type: Boolean },
      guessList: { type: Array },
    };
  }

  _onSave() {
    this.close();
  }

  _onToggle(name: string) {
    storage.dispatch({ name: "toggle-settings", payload: name });
    this.requestUpdate();
  }

  _setValue(key: string, value: string) {
    storage.dispatch({ name: "update-settings", payload: { key, value } });
    this.requestUpdate();
  }

  render() {
    // TODO: add this + clear guess list for the day

    // <div class="settings-row">
    //   <div class="settings-row-info">
    //     <div class="settings-row-info-title">Super Hard Mode</div>
    //     <div class="settings-row-info-description">
    //       Include dependencies and other territories. Enabling this will remove your guesses for today and might
    //       change the correct answer.
    //     </div>
    //   </div>
    //   <div class="settings-row-toggle">
    //     <mpl-switch
    //       @click=${() => this._onToggle("includeAll")}
    //       .checked=${storage.state.settings.includeAll}
    //     ></mpl-switch>
    //   </div>
    // </div>
    return html`<mpl-modal-dialog
      title="Settings"
      .open=${this.open}
      .close=${this.close}
    >
      <div class="settings-row">
        <div class="settings-row-info">
          <div class="settings-row-info-title">Dark Theme</div>
        </div>
        <div class="settings-row-toggle">
          <mpl-switch
            @click=${() => this._onToggle("darkMode")}
            .checked=${storage.state.settings.darkMode}
          ></mpl-switch>
        </div>
      </div>
      <div class="settings-row">
        <div class="settings-row-info">
          <div class="settings-row-info-title">Hard Mode</div>
          <div class="settings-row-info-description">Hide region indicator</div>
        </div>
        <div class="settings-row-toggle">
          <mpl-switch
            @click=${() => this._onToggle("hardMode")}
            .checked=${storage.state.settings.hardMode}
          ></mpl-switch>
        </div>
      </div>

      <div class="settings-row">
        <div class="settings-row-info">
          <div class="settings-row-info-title">Metric System</div>
        </div>
        <div class="settings-row-toggle">
          <mpl-switch
            @click=${() =>
              this._setValue(
                "unit",
                storage.state.settings.unit === "kilometers"
                  ? "miles"
                  : "kilometers"
              )}
            .checked=${storage.state.settings.unit === "kilometers"}
          ></mpl-switch>
        </div>
      </div>
    </mpl-modal-dialog> `;
  }
}
