import cities from "../cities.json";
import { City } from "../types";
import { getFullDate } from "./date";
import { storage } from "./storage";

export const zerofill = (x: number, size: number): string =>
  `${new Array(size - `${x}`.length).fill("0").join("")}${x}`;

export function getItemInList<ListItem>(
  list: ListItem[],
  itemIndex: number
): ListItem {
  return list[itemIndex % list.length];
}

export function getCitiesForGame(): City[] {
  const citiesList = storage.state.settings.includeAll
    ? cities
    : cities.filter((city) => city.hidden === false);

  return citiesList;
}

export function getCorrectCity(): City {
  const day = +getFullDate(new Date());

  const correctCity = getItemInList(getCitiesForGame(), day);

  return correctCity;
}
