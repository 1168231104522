[
  {
    "city": "Ankara",
    "unicodeFlag": "🇹🇷",
    "distance": 1272.76,
    "isCorrect": false,
    "region": "Asia"
  },
  {
    "city": "Lisbon",
    "unicodeFlag": "🇵🇹",
    "distance": 2360.10,
    "isCorrect": false,
    "region": "Europe"
  },
  {
    "city": "Sarajevo",
    "unicodeFlag": "🇧🇦",
    "distance": 0,
    "isCorrect": true,
    "region": "Europe"
  }
]
