import { html, css, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { City } from "../types";

@customElement("mpl-correct-city")
export class CorrectCity extends LitElement {
  static styles = css`
    .wrapper {
      text-align: center;
      color: var(--color-text);
    }
    .country {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: var(--spacing-xs);
      font-size: 1.2rem;
    }
    .city {
      font-size: 2rem;
    }
    .flag {
      font-size: 3rem;
      margin-left: var(--spacing-m);
    }
  `;

  correctCity: City;

  static get properties() {
    return {
      correctCity: { type: Object },
    };
  }

  render() {
    return html` <div class="wrapper">
      <div class="city"><b>${this.correctCity.name} </b></div>
      <div class="country">
        ${this.correctCity.unicodeFlag} ${this.correctCity.country}
      </div>
    </div>`;
  }
}
