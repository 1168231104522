import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { City, Guess } from "../types";
import { storage } from "../utils/storage";

@customElement("mpl-win-modal")
export class WinModal extends LitElement {
  static styles = css`
    .message {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: var(--color-text);
    }
  `;
  open = false;
  close = () => {};
  guessList: Guess[] = [];
  correctCity: City;

  static get properties() {
    return {
      open: { type: Boolean },
      correctCity: { type: Object },
      guessList: { type: Array },
    };
  }

  render() {
    if (!this.correctCity) {
      return;
    }

    return html`<mpl-modal-dialog
      title="You win 🎉"
      .open=${this.open}
      .close=${this.close}
    >
      <div class="message">Congrats! You won the game.</div>
      <br />
      <mpl-correct-city .correctCity=${this.correctCity}></mpl-correct-city>
      <br />
      <div class="message">Win again tomorrow and build your streak!</div>
      <mpl-stats .guessList=${this.guessList}></mpl-stats>
    </mpl-modal-dialog>`;
  }
}
