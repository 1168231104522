import { html, css, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { modalEvents } from "../utils/events/modalEventBus";

@customElement("mpl-header")
export class Header extends LitElement {
  static styles = css`
    .header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;
      border-bottom: 1px solid var(--color-border);
      background-color: var(--color-header-background);
    }
    .header-inner {
      max-width: var(--app-width);
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: var(--spacing-s) var(--spacing-xxl);
      box-sizing: border-box;
    }
    .logo-wrapper {
      display: flex;
      align-items: center;
    }
    .logo {
      height: 35px;
      margin-right: var(--spacing-m);
    }
    h1 {
      margin: 0;
      font-size: 2rem;
      font-weight: 300;
      color: var(--color-text);
    }

    .icons-wrapper {
      display: flex;
    }

    .icon {
      cursor: pointer;
      padding: var(--spacing-s);
      margin-left: var(--spacing-s);
      transition: all 0.2s;
    }

    .icon svg {
      height: 20px;
      fill: var(--color-text);
      transition: all 0.2s;
    }

    .icon:hover {
      padding-top: var(--spacing-xs);
    }

    .icon:hover svg {
      fill: var(--color-primary);
    }
  `;

  _openStats() {
    modalEvents.showModal("stats");
  }

  _openHelp() {
    modalEvents.showModal("welcome");
  }

  _openSettings() {
    modalEvents.showModal("settings");
  }

  render() {
    return html`<div class="header">
      <div class="header-inner">
        <div class="logo-wrapper">
          <svg
            class="logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
          >
            <path
              fill="#544cbb"
              d="M408 120c0 54.6-73.1 151.9-105.2 191.1-7.7 10.5-21.9 10.5-29.6 0C241.1 271.9 168 174.6 168 120 168 53.73 221.7 0 288 0s120 53.73 120 120zm-120 32c22.1 0 40-17.9 40-40 0-22.09-17.9-40-40-40s-40 17.91-40 40c0 22.1 17.9 40 40 40zm137.6 27.8c.5-1.2 1-2.4 1.5-3.7l116-46.4c15.8-6.3 32.9 5.3 32.9 22.3v270.8c0 9.8-6 18.6-15.1 22.3L416 503V200.4c3.5-6.9 6.7-13.7 9.6-20.6zm-275.2 0c2.9 6.9 6.1 13.7 9.6 20.6v251.4L32.91 502.7C17.15 508.1 0 497.4 0 480.4V209.6c0-9.8 5.975-19.5 15.09-22.3l122.51-49c2.4 14.2 7.3 28.3 12.8 41.5zm177.4 151.3c13.9-16.5 35.7-44.8 56.2-76.1v249.3l-192-54.9V255c20.5 31.3 42.3 59.6 56.2 76.1 20.5 26.5 59.1 26.5 79.6 0z"
            />
          </svg>
          <h1>mapple</h1>
        </div>
        <div class="icons-wrapper">
          <div @click=${this._openStats} class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                d="M64 400C64 408.8 71.16 416 80 416H480C497.7 416 512 430.3 512 448C512 465.7 497.7 480 480 480H80C35.82 480 0 444.2 0 400V64C0 46.33 14.33 32 32 32C49.67 32 64 46.33 64 64V400zM342.6 278.6C330.1 291.1 309.9 291.1 297.4 278.6L240 221.3L150.6 310.6C138.1 323.1 117.9 323.1 105.4 310.6C92.88 298.1 92.88 277.9 105.4 265.4L217.4 153.4C229.9 140.9 250.1 140.9 262.6 153.4L320 210.7L425.4 105.4C437.9 92.88 458.1 92.88 470.6 105.4C483.1 117.9 483.1 138.1 470.6 150.6L342.6 278.6z"
              />
            </svg>
          </div>
          <div @click=${this._openHelp} class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                d="M470.6 425.4c12.5 12.5 12.5 32.7 0 45.2s-32.7 12.5-45.2 0l-13.3-12.4c-42.5 33.7-96.9 53.8-157 53.8-58.3 0-112.7-20.1-156.08-53.8l-12.39 12.4c-12.5 12.5-32.76 12.5-45.26 0-12.49-12.5-12.49-32.7 0-45.2l12.39-13.3C20.07 369.6 0 315.2 0 255.1c0-58.3 20.07-112.7 53.76-156.08L41.37 86.63c-12.49-12.5-12.49-32.76 0-45.26 12.5-12.49 32.76-12.49 45.26 0l12.39 12.39C142.4 20.07 196.8 0 255.1 0c60.1 0 114.5 20.07 157 53.76l13.3-12.39c12.5-12.49 32.7-12.49 45.2 0 12.5 12.5 12.5 32.76 0 45.26l-12.4 12.39C491.9 142.4 512 196.8 512 255.1c0 60.1-20.1 114.5-53.8 157l12.4 13.3zm-161.3-70.9c-15.9 8.6-34 13.5-54.2 13.5-18.4 0-36.5-4.9-52.4-13.5l-57.9 58c31.3 22.4 69.7 35.5 110.3 35.5 42.4 0 80.8-13.1 112.1-35.5l-57.9-58zM448 255.1c0-40.6-13.1-79-35.5-110.3l-58 57.9c8.6 15.9 13.5 34 13.5 53.3 0 19.3-4.9 37.4-13.5 53.3l58 57.9C434.9 335.9 448 297.5 448 256v-.9zm-192.9-192c-40.6 0-79 14.04-110.3 36.4l57.9 58c15.9-8.6 34-14.4 52.4-14.4 20.2 0 38.3 5.8 54.2 14.4l57.9-58C335.9 77.14 297.5 63.1 256 63.1h-.9zm-97.6 246.2c-8.6-15.9-14.4-34-14.4-54.2 0-18.4 5.8-36.5 14.4-52.4l-58-57.9c-22.36 31.3-36.4 69.7-36.4 110.3 0 42.4 14.04 80.8 36.4 112.1l58-57.9zm97.6-102.2c-25.6 0-48 22.4-48 48 0 27.4 22.4 48 48 48 27.4 0 48.9-20.6 48.9-48 0-25.6-21.5-48-48.9-48z"
              />
            </svg>
          </div>
          <div @click=${this._openSettings} class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                d="M495.9 166.6c3.3 8.6.5 18.3-6.3 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4 0 8.6-.6 17.1-1.7 25.4l43.3 39.4c6.8 6.3 9.6 16 6.3 24.6-4.4 11.9-9.7 23.4-15.7 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.3-6 7.1-15.7 9.6-24.5 6.8l-55.7-17.8c-13.4 10.3-29.1 18.9-44 25.5l-12.5 57.1c-2 9-9 15.4-18.2 17.8-13.8 2.3-28 3.5-43.4 3.5-13.6 0-27.8-1.2-41.6-3.5-9.2-2.4-16.2-8.8-18.2-17.8l-12.5-57.1c-15.8-6.6-30.6-15.2-44-25.5l-55.66 17.8c-8.84 2.8-18.59.3-24.51-6.8-8.11-9.9-15.51-20.3-22.11-31.3l-4.68-8.1c-6.07-10.9-11.35-22.4-15.78-34.3-3.24-8.6-.51-18.3 6.35-24.6l43.26-39.4C64.57 273.1 64 264.6 64 256c0-8.6.57-17.1 1.67-25.4l-43.26-39.4c-6.86-6.3-9.59-15.9-6.35-24.6 4.43-11.9 9.72-23.4 15.78-34.3l4.67-8.1c6.61-11 14.01-21.4 22.12-31.25 5.92-7.15 15.67-9.63 24.51-6.81l55.66 17.76c13.4-10.34 28.2-18.94 44-25.47l12.5-57.1c2-9.08 9-16.29 18.2-17.82C227.3 1.201 241.5 0 256 0s28.7 1.201 42.5 3.51c9.2 1.53 16.2 8.74 18.2 17.82l12.5 57.1c14.9 6.53 30.6 15.13 44 25.47l55.7-17.76c8.8-2.82 18.5-.34 24.5 6.81 8.1 9.85 15.5 20.25 22.1 31.25l4.7 8.1c6 10.9 11.3 22.4 15.7 34.3zM256 336c44.2 0 80-35.8 80-80.9 0-43.3-35.8-80-80-80s-80 36.7-80 80c0 45.1 35.8 80.9 80 80.9z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>`;
  }
}
