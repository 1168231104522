import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";

import { City, Guess } from "../types";
import { getCitiesForGame } from "../utils/utils";

const MAX_MATCHES = 2;
const MIN_SEARCH_LENGTH = 3;

@customElement("mpl-city-input")
export class CityInput extends LitElement {
  // ---------------- properties ---------------- \\
  @property() onPick: (city: City) => {};

  filteredCitiesList: City[] = [];
  guessList: Guess[] = [];
  isDisabled: boolean = false;

  static get properties() {
    return {
      filteredCitiesList: { type: Array },
      guessList: { type: Array },
      isDisabled: { type: Boolean },
    };
  }

  // ---------------- functions ---------------- \\

  private _onSelect(item: City) {
    this.onPick(item);
    this.filteredCitiesList = [];
  }

  private _isInGuessList(city: City) {
    return !!this.guessList.find((guess) => guess.city === city.name);
  }

  private _getNormalizedString(term: string) {
    return term.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  private _includesTerm(city: City, searchTerm: string) {
    return (
      !!this._getNormalizedString(city.name)
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      !!this._getNormalizedString(city.country)
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  }

  private _sortByStartsWith(citiesList: City[], searchTerm: string): City[] {
    return citiesList.sort((a, b) =>
      a.name.indexOf(searchTerm.toLocaleLowerCase()) <
        b.name.indexOf(searchTerm.toLocaleLowerCase()) ||
      a.country.indexOf(searchTerm.toLocaleLowerCase()) <
        b.country.indexOf(searchTerm.toLocaleLowerCase())
        ? -1
        : 1
    );
  }

  private _onChange(searchTerm: string) {
    if (searchTerm.length < MIN_SEARCH_LENGTH) {
      this.filteredCitiesList = [];
      return;
    }

    const filteredList = getCitiesForGame().filter(
      (city: City) =>
        !this._isInGuessList(city) && this._includesTerm(city, searchTerm)
    );

    this.filteredCitiesList = this._sortByStartsWith(
      filteredList,
      searchTerm
    ).slice(0, MAX_MATCHES);
  }

  render() {
    return html`
      <div class="wrapper">
        <mpl-autocomplete
          .onChange=${this._onChange.bind(this)}
          .onSelect=${this._onSelect.bind(this)}
          .list=${this.filteredCitiesList}
          .isDisabled=${this.isDisabled}
        ></mpl-autocomplete>
      </div>
    `;
  }
}
