[
  {
    "name": "Ottawa",
    "country": "Canada",
    "lat": 45.4247,
    "lng": -75.695,
    "population": 994837,
    "unicodeFlag": "🇨🇦",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Roseau",
    "country": "Dominica",
    "lat": 15.3,
    "lng": -61.3833,
    "population": 16582,
    "unicodeFlag": "🇩🇲",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Mata Utu",
    "country": "Wallis and Futuna Islands",
    "lat": -13.2825,
    "lng": -176.1736,
    "population": 1029,
    "unicodeFlag": "🇼🇫",
    "region": "Oceania",
    "hidden": true
  },
  {
    "name": "Algiers",
    "country": "Algeria",
    "lat": 36.7764,
    "lng": 3.0586,
    "population": 3415811,
    "unicodeFlag": "🇩🇿",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "San Juan",
    "country": "Puerto Rico",
    "lat": 18.4037,
    "lng": -66.0636,
    "population": 335468,
    "unicodeFlag": "🇵🇷",
    "region": "Americas",
    "hidden": true
  },
  {
    "name": "Saint-Pierre",
    "country": "Saint Pierre and Miquelon",
    "lat": 46.7811,
    "lng": -56.1764,
    "population": 5406,
    "unicodeFlag": "🇵🇲",
    "region": "Americas",
    "hidden": true
  },
  {
    "name": "N'Djamena",
    "country": "Chad",
    "lat": 12.11,
    "lng": 15.05,
    "population": 1092066,
    "unicodeFlag": "🇹🇩",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Castries",
    "country": "Saint Lucia",
    "lat": 14.0167,
    "lng": -60.9833,
    "population": 70000,
    "unicodeFlag": "🇱🇨",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Malabo",
    "country": "Equatorial Guinea",
    "lat": 3.7521,
    "lng": 8.7737,
    "population": 187302,
    "unicodeFlag": "🇬🇶",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Marigot",
    "country": "Saint Martin",
    "lat": 18.0706,
    "lng": -63.0847,
    "population": 5700,
    "unicodeFlag": "🇲🇫",
    "region": "Americas",
    "hidden": true
  },
  {
    "name": "Antananarivo",
    "country": "Madagascar",
    "lat": -18.9386,
    "lng": 47.5214,
    "population": 2610000,
    "unicodeFlag": "🇲🇬",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Funafuti",
    "country": "Tuvalu",
    "lat": -8.5167,
    "lng": 179.2167,
    "population": 6025,
    "unicodeFlag": "🇹🇻",
    "region": "Oceania",
    "hidden": false
  },
  {
    "name": "Tirana",
    "country": "Albania",
    "lat": 41.33,
    "lng": 19.82,
    "population": 418495,
    "unicodeFlag": "🇦🇱",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Cockburn Town",
    "country": "Turks and Caicos Islands",
    "lat": 21.4664,
    "lng": -71.136,
    "population": 4831,
    "unicodeFlag": "🇹🇨",
    "region": "Americas",
    "hidden": true
  },
  {
    "name": "The Valley",
    "country": "Anguilla",
    "lat": 18.2167,
    "lng": -63.05,
    "population": 1067,
    "unicodeFlag": "🇦🇮",
    "region": "Americas",
    "hidden": true
  },
  {
    "name": "Amman",
    "country": "Jordan",
    "lat": 31.95,
    "lng": 35.9333,
    "population": 4007526,
    "unicodeFlag": "🇯🇴",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Alofi",
    "country": "Niue",
    "lat": -19.056,
    "lng": -169.921,
    "population": 597,
    "unicodeFlag": "🇳🇺",
    "region": "Oceania",
    "hidden": true
  },
  {
    "name": "Gibraltar",
    "country": "Gibraltar",
    "lat": 36.1324,
    "lng": -5.3781,
    "population": 33691,
    "unicodeFlag": "🇬🇮",
    "region": "Europe",
    "hidden": true
  },
  {
    "name": "Rome",
    "country": "Italy",
    "lat": 41.8931,
    "lng": 12.4828,
    "population": 2872800,
    "unicodeFlag": "🇮🇹",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Flying Fish Cove",
    "country": "Christmas Island",
    "lat": -10.4167,
    "lng": 105.7167,
    "population": 1599,
    "unicodeFlag": "🇨🇽",
    "region": "Asia",
    "hidden": true
  },
  {
    "name": "Beirut",
    "country": "Lebanon",
    "lat": 33.8869,
    "lng": 35.5131,
    "population": 361366,
    "unicodeFlag": "🇱🇧",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Riga",
    "country": "Latvia",
    "lat": 56.9475,
    "lng": 24.1069,
    "population": 614618,
    "unicodeFlag": "🇱🇻",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Guatemala City",
    "country": "Guatemala",
    "lat": 14.6099,
    "lng": -90.5252,
    "population": 2450212,
    "unicodeFlag": "🇬🇹",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "San Jose",
    "country": "Costa Rica",
    "lat": 9.9333,
    "lng": -84.0833,
    "population": 288054,
    "unicodeFlag": "🇨🇷",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Seoul",
    "country": "South Korea (Republic of Korea)",
    "lat": 37.56,
    "lng": 126.99,
    "population": 21794000,
    "unicodeFlag": "🇰🇷",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Helsinki",
    "country": "Finland",
    "lat": 60.1756,
    "lng": 24.9342,
    "population": 642045,
    "unicodeFlag": "🇫🇮",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Tripolis",
    "country": "Libya",
    "lat": 32.8752,
    "lng": 13.1875,
    "population": 1126000,
    "unicodeFlag": "🇱🇾",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Hamilton",
    "country": "Bermuda",
    "lat": 32.2942,
    "lng": -64.7839,
    "population": 854,
    "unicodeFlag": "🇧🇲",
    "region": "Americas",
    "hidden": true
  },
  {
    "name": "San Salvador",
    "country": "El Salvador",
    "lat": 13.6989,
    "lng": -89.1914,
    "population": 567698,
    "unicodeFlag": "🇸🇻",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Havana",
    "country": "Cuba",
    "lat": 23.1367,
    "lng": -82.3589,
    "population": 2141652,
    "unicodeFlag": "🇨🇺",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Ouagadougou",
    "country": "Burkina Faso",
    "lat": 12.3572,
    "lng": -1.5353,
    "population": 1626950,
    "unicodeFlag": "🇧🇫",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Kinshasa",
    "country": "Democratic Republic of the Congo",
    "lat": -4.3233,
    "lng": 15.3081,
    "population": 13528000,
    "unicodeFlag": "🇨🇩",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Vaduz",
    "country": "Liechtenstein",
    "lat": 47.1415,
    "lng": 9.5215,
    "population": 36281,
    "unicodeFlag": "🇱🇮",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Ulan Bator",
    "country": "Mongolia",
    "lat": 47.9203,
    "lng": 106.9172,
    "population": 1396288,
    "unicodeFlag": "🇲🇳",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Colombo",
    "country": "Sri Lanka",
    "lat": 6.9167,
    "lng": 79.8333,
    "population": 752993,
    "unicodeFlag": "🇱🇰",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Juba",
    "country": "South Sudan",
    "lat": 4.85,
    "lng": 31.6,
    "population": 372410,
    "unicodeFlag": "🇸🇸",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Pyongyang",
    "country": "North Korea (Democratic People’s Republic of Korea)",
    "lat": 39.03,
    "lng": 125.73,
    "population": 2863000,
    "unicodeFlag": "🇰🇵",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Belmopan",
    "country": "Belize",
    "lat": 17.25,
    "lng": -88.7675,
    "population": 17222,
    "unicodeFlag": "🇧🇿",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Bissau",
    "country": "Guinea-Bissau",
    "lat": 11.8592,
    "lng": -15.5956,
    "population": 395954,
    "unicodeFlag": "🇬🇼",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Monrovia",
    "country": "Liberia",
    "lat": 6.3106,
    "lng": -10.8047,
    "population": 1021762,
    "unicodeFlag": "🇱🇷",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Kigali",
    "country": "Rwanda",
    "lat": -1.9536,
    "lng": 30.0606,
    "population": 1156663,
    "unicodeFlag": "🇷🇼",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Lome",
    "country": "Togo",
    "lat": 6.1319,
    "lng": 1.2228,
    "population": 837437,
    "unicodeFlag": "🇹🇬",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Kiev",
    "country": "Ukraine",
    "lat": 50.45,
    "lng": 30.5236,
    "population": 2963199,
    "unicodeFlag": "🇺🇦",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Yaren",
    "country": "Nauru",
    "lat": -0.55085,
    "lng": 166.9252,
    "population": 1100,
    "unicodeFlag": "🇳🇷",
    "region": "Oceania",
    "hidden": false
  },
  {
    "name": "Yaounde",
    "country": "Cameroon",
    "lat": 3.8578,
    "lng": 11.5181,
    "population": 2440462,
    "unicodeFlag": "🇨🇲",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Sanaa",
    "country": "Yemen",
    "lat": 15.35,
    "lng": 44.2,
    "population": 2957000,
    "unicodeFlag": "🇾🇪",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Saint Helier",
    "country": "Jersey",
    "lat": 49.1833,
    "lng": -2.1167,
    "population": 33522,
    "unicodeFlag": "🇯🇪",
    "region": "Europe",
    "hidden": true
  },
  {
    "name": "Dublin",
    "country": "Ireland",
    "lat": 53.3497,
    "lng": -6.2603,
    "population": 1173179,
    "unicodeFlag": "🇮🇪",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Podgorica",
    "country": "Montenegro",
    "lat": 42.4397,
    "lng": 19.2661,
    "population": 174515,
    "unicodeFlag": "🇲🇪",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Suva",
    "country": "Fiji",
    "lat": -18.1333,
    "lng": 178.4333,
    "population": 88271,
    "unicodeFlag": "🇫🇯",
    "region": "Oceania",
    "hidden": false
  },
  {
    "name": "Nassau",
    "country": "Bahamas",
    "lat": 25.0667,
    "lng": -77.3333,
    "population": 274400,
    "unicodeFlag": "🇧🇸",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "George Town",
    "country": "Cayman Islands",
    "lat": 19.2866,
    "lng": -81.3744,
    "population": 34399,
    "unicodeFlag": "🇰🇾",
    "region": "Americas",
    "hidden": true
  },
  {
    "name": "Kuwait City",
    "country": "Kuwait",
    "lat": 29.375,
    "lng": 47.98,
    "population": 2989000,
    "unicodeFlag": "🇰🇼",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Abu Dhabi",
    "country": "United Arab Emirates",
    "lat": 24.4781,
    "lng": 54.3686,
    "population": 1000000,
    "unicodeFlag": "🇦🇪",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Vienna",
    "country": "Austria",
    "lat": 48.2083,
    "lng": 16.3731,
    "population": 1911191,
    "unicodeFlag": "🇦🇹",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Porto-Novo",
    "country": "Benin",
    "lat": 6.4,
    "lng": 2.52,
    "population": 762000,
    "unicodeFlag": "🇧🇯",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Noumea",
    "country": "New Caledonia",
    "lat": -22.2625,
    "lng": 166.4443,
    "population": 94285,
    "unicodeFlag": "🇳🇨",
    "region": "Oceania",
    "hidden": true
  },
  {
    "name": "Brazzaville",
    "country": "Congo (Republic of the)",
    "lat": -4.2667,
    "lng": 15.2833,
    "population": 1827000,
    "unicodeFlag": "🇨🇬",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Georgetown",
    "country": "Guyana",
    "lat": 6.7833,
    "lng": -58.1667,
    "population": 200500,
    "unicodeFlag": "🇬🇾",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Doha",
    "country": "Qatar",
    "lat": 25.3,
    "lng": 51.5333,
    "population": 1312947,
    "unicodeFlag": "🇶🇦",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Adamstown",
    "country": "Pitcairn",
    "lat": -25.0667,
    "lng": -130.0833,
    "population": 49,
    "unicodeFlag": "🇵🇳",
    "region": "Oceania",
    "hidden": true
  },
  {
    "name": "Damascus",
    "country": "Syria",
    "lat": 33.5131,
    "lng": 36.2919,
    "population": 1754000,
    "unicodeFlag": "🇸🇾",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Vatican City",
    "country": "Vatican",
    "lat": 41.9,
    "lng": 12.4478,
    "population": 825,
    "unicodeFlag": "🇻🇦",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Lusaka",
    "country": "Zambia",
    "lat": -15.4167,
    "lng": 28.2833,
    "population": 1742979,
    "unicodeFlag": "🇿🇲",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Sofia",
    "country": "Bulgaria",
    "lat": 42.6979,
    "lng": 23.3217,
    "population": 1355142,
    "unicodeFlag": "🇧🇬",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Stanley",
    "country": "Falkland Islands",
    "lat": -51.7,
    "lng": -57.85,
    "population": 2213,
    "unicodeFlag": "🇫🇰",
    "region": "Americas",
    "hidden": true
  },
  {
    "name": "Monaco",
    "country": "Monaco",
    "lat": 43.7396,
    "lng": 7.4069,
    "population": 36371,
    "unicodeFlag": "🇲🇨",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Port Vila",
    "country": "Vanuatu",
    "lat": -17.7333,
    "lng": 168.3167,
    "population": 51437,
    "unicodeFlag": "🇻🇺",
    "region": "Oceania",
    "hidden": false
  },
  {
    "name": "Brussels",
    "country": "Belgium",
    "lat": 50.8333,
    "lng": 4.3333,
    "population": 1743000,
    "unicodeFlag": "🇧🇪",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Douglas",
    "country": "Isle of Man",
    "lat": 54.15,
    "lng": -4.4819,
    "population": 27938,
    "unicodeFlag": "🇮🇲",
    "region": "Europe",
    "hidden": true
  },
  {
    "name": "Washington",
    "country": "United States",
    "lat": 38.9047,
    "lng": -77.0163,
    "population": 5379184,
    "unicodeFlag": "🇺🇸",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "London",
    "country": "United Kingdom",
    "lat": 51.5072,
    "lng": -0.1275,
    "population": 10979000,
    "unicodeFlag": "🇬🇧",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Philipsburg",
    "country": "Sint Maarten",
    "lat": 18.0256,
    "lng": -63.0492,
    "population": 1894,
    "unicodeFlag": "🇸🇽",
    "region": "Americas",
    "hidden": true
  },
  {
    "name": "Rabat",
    "country": "Morocco",
    "lat": 34.0253,
    "lng": -6.8361,
    "population": 572717,
    "unicodeFlag": "🇲🇦",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Naypyidaw",
    "country": "Myanmar",
    "lat": 16.8,
    "lng": 96.15,
    "population": 5430000,
    "unicodeFlag": "🇲🇲",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Kingstown",
    "country": "St. Vincent & Grenadines",
    "lat": 13.1667,
    "lng": -61.2333,
    "population": 25000,
    "unicodeFlag": "🇻🇨",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Astana",
    "country": "Kazakhstan",
    "lat": 51.1333,
    "lng": 71.4333,
    "population": 1078362,
    "unicodeFlag": "🇰🇿",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Banjul",
    "country": "Gambia",
    "lat": 13.4531,
    "lng": -16.5775,
    "population": 31356,
    "unicodeFlag": "🇬🇲",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Maseru",
    "country": "Lesotho",
    "lat": -29.31,
    "lng": 27.48,
    "population": 330790,
    "unicodeFlag": "🇱🇸",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Port-au-Prince",
    "country": "Haiti",
    "lat": 18.5425,
    "lng": -72.3386,
    "population": 987310,
    "unicodeFlag": "🇭🇹",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Amsterdam",
    "country": "Netherlands",
    "lat": 52.0767,
    "lng": 4.2986,
    "population": 1406000,
    "unicodeFlag": "🇳🇱",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Libreville",
    "country": "Gabon",
    "lat": 0.3901,
    "lng": 9.4544,
    "population": 797003,
    "unicodeFlag": "🇬🇦",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Road Town",
    "country": "British Virgin Islands",
    "lat": 18.4167,
    "lng": -64.6167,
    "population": 12603,
    "unicodeFlag": "🇻🇬",
    "region": "Americas",
    "hidden": true
  },
  {
    "name": "Paramaribo",
    "country": "Suriname",
    "lat": 5.8667,
    "lng": -55.1667,
    "population": 223757,
    "unicodeFlag": "🇸🇷",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Bamako",
    "country": "Mali",
    "lat": 12.6458,
    "lng": -7.9922,
    "population": 2009109,
    "unicodeFlag": "🇲🇱",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Tehran",
    "country": "Iran",
    "lat": 35.7,
    "lng": 51.4167,
    "population": 13633000,
    "unicodeFlag": "🇮🇷",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Maputo",
    "country": "Mozambique",
    "lat": -25.9153,
    "lng": 32.5764,
    "population": 1191613,
    "unicodeFlag": "🇲🇿",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Santo Domingo",
    "country": "Dominican Republic",
    "lat": 18.4764,
    "lng": -69.8933,
    "population": 2581827,
    "unicodeFlag": "🇩🇴",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Sarajevo",
    "country": "Bosnia and Herzegovina",
    "lat": 43.8667,
    "lng": 18.4167,
    "population": 275524,
    "unicodeFlag": "🇧🇦",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Taipei",
    "country": "Taiwan",
    "lat": 25.0478,
    "lng": 121.5319,
    "population": 2646567,
    "unicodeFlag": "🇹🇼",
    "region": "Asia",
    "hidden": true
  },
  {
    "name": "Bujumbura",
    "country": "Burundi",
    "lat": -3.3825,
    "lng": 29.3611,
    "population": 658859,
    "unicodeFlag": "🇧🇮",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Pristina",
    "country": "Kosovo",
    "lat": 42.6667,
    "lng": 21.1667,
    "population": 204725,
    "unicodeFlag": "🇽🇰",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Beijing",
    "country": "China",
    "lat": 39.905,
    "lng": 116.3914,
    "population": 19433000,
    "unicodeFlag": "🇨🇳",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Addis Ababa",
    "country": "Ethiopia",
    "lat": 9.0272,
    "lng": 38.7369,
    "population": 3041002,
    "unicodeFlag": "🇪🇹",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Nicosia",
    "country": "Cyprus",
    "lat": 35.1725,
    "lng": 33.365,
    "population": 330000,
    "unicodeFlag": "🇨🇾",
    "region": "Europe",
    "hidden": false
  }
  
  ,
  {
    "name": "Manila",
    "country": "Philippines",
    "lat": 14.6,
    "lng": 120.9833,
    "population": 23088000,
    "unicodeFlag": "🇵🇭",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Bridgetown",
    "country": "Barbados",
    "lat": 13.0975,
    "lng": -59.6167,
    "population": 110000,
    "unicodeFlag": "🇧🇧",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Sao Tome",
    "country": "Sao Tome & Principe",
    "lat": 0.3333,
    "lng": 6.7333,
    "population": 56166,
    "unicodeFlag": "🇸🇹",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Sucre",
    "country": "Bolivia",
    "lat": -16.4942,
    "lng": -68.1475,
    "population": 2867504,
    "unicodeFlag": "🇧🇴",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Copenhagen",
    "country": "Denmark",
    "lat": 55.6761,
    "lng": 12.5689,
    "population": 602481,
    "unicodeFlag": "🇩🇰",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Mbabane",
    "country": "Eswatini (Swaziland)",
    "lat": -26.3208,
    "lng": 31.1617,
    "population": 94874,
    "unicodeFlag": "🇸🇿",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Gustavia",
    "country": "Saint Barthélemy",
    "lat": 17.8958,
    "lng": -62.8508,
    "population": 2300,
    "unicodeFlag": "🇧🇱",
    "region": "Americas",
    "hidden": true
  },
  {
    "name": "Skopje",
    "country": "North Macedonia",
    "lat": 41.9833,
    "lng": 21.4333,
    "population": 640000,
    "unicodeFlag": "🇲🇰",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Kathmandu",
    "country": "Nepal",
    "lat": 27.7167,
    "lng": 85.3667,
    "population": 975453,
    "unicodeFlag": "🇳🇵",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Ngerulmud",
    "country": "Palau",
    "lat": 7.5006,
    "lng": 134.6242,
    "population": 271,
    "unicodeFlag": "🇵🇼",
    "region": "Oceania",
    "hidden": false
  },
  {
    "name": "Dili",
    "country": "Timor-Leste",
    "lat": -8.5586,
    "lng": 125.5736,
    "population": 222323,
    "unicodeFlag": "🇹🇱",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Ashgabat",
    "country": "Turkmenistan",
    "lat": 37.95,
    "lng": 58.3833,
    "population": 1031992,
    "unicodeFlag": "🇹🇲",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Dakar",
    "country": "Senegal",
    "lat": 14.7319,
    "lng": -17.4572,
    "population": 1146053,
    "unicodeFlag": "🇸🇳",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Oranjestad",
    "country": "Aruba",
    "lat": 12.5186,
    "lng": -70.0358,
    "population": 34980,
    "unicodeFlag": "🇦🇼",
    "region": "Americas",
    "hidden": true
  },
  {
    "name": "Basse-Terre",
    "country": "Guadeloupe",
    "lat": 16,
    "lng": -61.7167,
    "population": 11730,
    "unicodeFlag": "🇬🇵",
    "region": "Americas",
    "hidden": true
  },
  {
    "name": "Berlin",
    "country": "Germany",
    "lat": 52.5167,
    "lng": 13.3833,
    "population": 3644826,
    "unicodeFlag": "🇩🇪",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Brasilia",
    "country": "Brazil",
    "lat": -15.7939,
    "lng": -47.8828,
    "population": 3015268,
    "unicodeFlag": "🇧🇷",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Bratislava",
    "country": "Slovakia",
    "lat": 48.1447,
    "lng": 17.1128,
    "population": 429564,
    "unicodeFlag": "🇸🇰",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Mogadishu",
    "country": "Somalia",
    "lat": 2.0408,
    "lng": 45.3425,
    "population": 2120000,
    "unicodeFlag": "🇸🇴",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Lilongwe",
    "country": "Malawi",
    "lat": -13.9833,
    "lng": 33.7833,
    "population": 781538,
    "unicodeFlag": "🇲🇼",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Abuja",
    "country": "Nigeria",
    "lat": 9.0556,
    "lng": 7.4914,
    "population": 1235880,
    "unicodeFlag": "🇳🇬",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Apia",
    "country": "Samoa",
    "lat": -13.8333,
    "lng": -171.8333,
    "population": 37708,
    "unicodeFlag": "🇼🇸",
    "region": "Oceania",
    "hidden": false
  },
  {
    "name": "Luxembourg",
    "country": "Luxembourg",
    "lat": 49.6106,
    "lng": 6.1328,
    "population": 124509,
    "unicodeFlag": "🇱🇺",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Caracas",
    "country": "Venezuela",
    "lat": 10.5,
    "lng": -66.9333,
    "population": 1943901,
    "unicodeFlag": "🇻🇪",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Jakarta",
    "country": "Indonesia",
    "lat": -6.2146,
    "lng": 106.8451,
    "population": 34540000,
    "unicodeFlag": "🇮🇩",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "New Delhi",
    "country": "India",
    "lat": 28.7,
    "lng": 77.2,
    "population": 142004,
    "unicodeFlag": "🇮🇳",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Saint-Denis",
    "country": "Réunion",
    "lat": -20.8789,
    "lng": 55.4481,
    "population": 146985,
    "unicodeFlag": "🇷🇪",
    "region": "Africa",
    "hidden": true
  },
  {
    "name": "Papeete",
    "country": "French Polynesia",
    "lat": -17.5334,
    "lng": -149.5667,
    "population": 25763,
    "unicodeFlag": "🇵🇫",
    "region": "Oceania",
    "hidden": true
  },
  {
    "name": "Djibouti",
    "country": "Djibouti",
    "lat": 11.595,
    "lng": 43.1481,
    "population": 562000,
    "unicodeFlag": "🇩🇯",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Saipan",
    "country": "Northern Mariana Islands",
    "lat": 15.2137,
    "lng": 145.7546,
    "population": 47565,
    "unicodeFlag": "🇲🇵",
    "region": "Oceania",
    "hidden": true
  },
  {
    "name": "Warsaw",
    "country": "Poland",
    "lat": 52.2167,
    "lng": 21.0333,
    "population": 1790658,
    "unicodeFlag": "🇵🇱",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Lima",
    "country": "Peru",
    "lat": -12.05,
    "lng": -77.0333,
    "population": 9848000,
    "unicodeFlag": "🇵🇪",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Mamoudzou",
    "country": "Mayotte",
    "lat": -12.7871,
    "lng": 45.275,
    "population": 71437,
    "unicodeFlag": "🇾🇹",
    "region": "Africa",
    "hidden": true
  },
  {
    "name": "Panama City",
    "country": "Panama",
    "lat": 9,
    "lng": -79.5,
    "population": 880691,
    "unicodeFlag": "🇵🇦",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Nuku'alofa",
    "country": "Tonga",
    "lat": -21.1347,
    "lng": -175.2083,
    "population": 23221,
    "unicodeFlag": "🇹🇴",
    "region": "Oceania",
    "hidden": false
  },
  {
    "name": "Managua",
    "country": "Nicaragua",
    "lat": 12.15,
    "lng": -86.2667,
    "population": 1028808,
    "unicodeFlag": "🇳🇮",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "San Marino",
    "country": "San Marino",
    "lat": 43.932,
    "lng": 12.4484,
    "population": 4040,
    "unicodeFlag": "🇸🇲",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Mexico City",
    "country": "Mexico",
    "lat": 19.4333,
    "lng": -99.1333,
    "population": 20996000,
    "unicodeFlag": "🇲🇽",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Valletta",
    "country": "Malta",
    "lat": 35.8978,
    "lng": 14.5125,
    "population": 6444,
    "unicodeFlag": "🇲🇹",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Pago Pago",
    "country": "American Samoa",
    "lat": -14.274,
    "lng": -170.7046,
    "population": 12576,
    "unicodeFlag": "🇦🇸",
    "region": "Oceania",
    "hidden": true
  },
  {
    "name": "Windhoek",
    "country": "Namibia",
    "lat": -22.57,
    "lng": 17.0836,
    "population": 322500,
    "unicodeFlag": "🇳🇦",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Gaborone",
    "country": "Botswana",
    "lat": -24.6569,
    "lng": 25.9086,
    "population": 231626,
    "unicodeFlag": "🇧🇼",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Zagreb",
    "country": "Croatia",
    "lat": 45.8,
    "lng": 15.95,
    "population": 790017,
    "unicodeFlag": "🇭🇷",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Conakry",
    "country": "Guinea",
    "lat": 9.5092,
    "lng": -13.7122,
    "population": 1667864,
    "unicodeFlag": "🇬🇳",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Nuuk",
    "country": "Greenland",
    "lat": 64.175,
    "lng": -51.7333,
    "population": 17635,
    "unicodeFlag": "🇬🇱",
    "region": "Americas",
    "hidden": true
  },
  {
    "name": "Bandar Seri Begawan",
    "country": "Brunei",
    "lat": 4.9167,
    "lng": 114.9167,
    "population": 50000,
    "unicodeFlag": "🇧🇳",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Yerevan",
    "country": "Armenia",
    "lat": 40.1814,
    "lng": 44.5144,
    "population": 1075800,
    "unicodeFlag": "🇦🇲",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Santiago",
    "country": "Chile",
    "lat": -33.45,
    "lng": -70.6667,
    "population": 7007000,
    "unicodeFlag": "🇨🇱",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Montevideo",
    "country": "Uruguay",
    "lat": -34.8667,
    "lng": -56.1667,
    "population": 1319108,
    "unicodeFlag": "🇺🇾",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Buenos Aires",
    "country": "Argentina",
    "lat": -34.5997,
    "lng": -58.3819,
    "population": 16157000,
    "unicodeFlag": "🇦🇷",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Asuncion",
    "country": "Paraguay",
    "lat": -25.3,
    "lng": -57.6333,
    "population": 1870000,
    "unicodeFlag": "🇵🇾",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Berne",
    "country": "Switzerland",
    "lat": 46.948,
    "lng": 7.4474,
    "population": 133798,
    "unicodeFlag": "🇨🇭",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Bangkok",
    "country": "Thailand",
    "lat": 13.75,
    "lng": 100.5167,
    "population": 17066000,
    "unicodeFlag": "🇹🇭",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Bishkek",
    "country": "Kyrgyzstan",
    "lat": 42.8667,
    "lng": 74.5667,
    "population": 1027200,
    "unicodeFlag": "🇰🇬",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Majuro",
    "country": "Marshall Islands",
    "lat": 7.0918,
    "lng": 171.3802,
    "population": 30000,
    "unicodeFlag": "🇲🇭",
    "region": "Oceania",
    "hidden": false
  },
  {
    "name": "Andorra la Vella",
    "country": "Andorra",
    "lat": 42.5,
    "lng": 1.5,
    "population": 22151,
    "unicodeFlag": "🇦🇩",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Jamestown",
    "country": "Saint Helena",
    "lat": -15.9251,
    "lng": -5.7179,
    "population": 629,
    "unicodeFlag": "🇸🇭",
    "region": "Africa",
    "hidden": true
  },
  {
    "name": "Ljubljana",
    "country": "Slovenia",
    "lat": 46.05,
    "lng": 14.5167,
    "population": 286745,
    "unicodeFlag": "🇸🇮",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Islamabad",
    "country": "Pakistan",
    "lat": 33.6989,
    "lng": 73.0369,
    "population": 1014825,
    "unicodeFlag": "🇵🇰",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Nouakchott",
    "country": "Mauritania",
    "lat": 18.0858,
    "lng": -15.9785,
    "population": 1077169,
    "unicodeFlag": "🇲🇷",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Basseterre",
    "country": "Saint Kitts and Nevis",
    "lat": 17.2983,
    "lng": -62.7342,
    "population": 13220,
    "unicodeFlag": "🇰🇳",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Singapur",
    "country": "Singapore",
    "lat": 1.3,
    "lng": 103.8,
    "population": 5745000,
    "unicodeFlag": "🇸🇬",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "St. George's",
    "country": "Grenada",
    "lat": 12.0444,
    "lng": -61.7417,
    "population": 33734,
    "unicodeFlag": "🇬🇩",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Tórshavn",
    "country": "Faroe Islands",
    "lat": 62,
    "lng": -6.7833,
    "population": 13326,
    "unicodeFlag": "🇫🇴",
    "region": "Europe",
    "hidden": true
  },
  {
    "name": "Dushanbe",
    "country": "Tajikistan",
    "lat": 38.5731,
    "lng": 68.7864,
    "population": 778500,
    "unicodeFlag": "🇹🇯",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Hagåtña",
    "country": "Guam",
    "lat": 13.4745,
    "lng": 144.7504,
    "population": 1051,
    "unicodeFlag": "🇬🇺",
    "region": "Oceania",
    "hidden": true
  },
  {
    "name": "Baku",
    "country": "Azerbaijan",
    "lat": 40.3667,
    "lng": 49.8352,
    "population": 2181800,
    "unicodeFlag": "🇦🇿",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Niamey",
    "country": "Niger",
    "lat": 13.5086,
    "lng": 2.1111,
    "population": 1026848,
    "unicodeFlag": "🇳🇪",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Moroni",
    "country": "Comoros",
    "lat": -11.7036,
    "lng": 43.2536,
    "population": 111329,
    "unicodeFlag": "🇰🇲",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Madrid",
    "country": "Spain",
    "lat": 40.4167,
    "lng": -3.7167,
    "population": 6026000,
    "unicodeFlag": "🇪🇸",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Tokyo",
    "country": "Japan",
    "lat": 35.6897,
    "lng": 139.6922,
    "population": 37977000,
    "unicodeFlag": "🇯🇵",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "East Jerusalem",
    "country": "State of Palestine",
    "lat": 31.783056,
    "lng": 35.216667,
    "population": 890428,
    "unicodeFlag": "🇵🇸",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Canberra",
    "country": "Australia",
    "lat": -35.2931,
    "lng": 149.1269,
    "population": 426704,
    "unicodeFlag": "🇦🇺",
    "region": "Oceania",
    "hidden": false
  },
  {
    "name": "Hanoi",
    "country": "Vietnam",
    "lat": 21.0245,
    "lng": 105.8412,
    "population": 7785000,
    "unicodeFlag": "🇻🇳",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Avarua",
    "country": "Cook Islands",
    "lat": -21.207,
    "lng": -159.771,
    "population": 4906,
    "unicodeFlag": "🇨🇰",
    "region": "Oceania",
    "hidden": true
  },
  {
    "name": "Yamoussoukro",
    "country": "Ivory Coast (Côte d’Ivoire)",
    "lat": 5.3364,
    "lng": -4.0267,
    "population": 4980000,
    "unicodeFlag": "🇨🇮",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Bangui",
    "country": "Central African Republic",
    "lat": 4.3732,
    "lng": 18.5628,
    "population": 889231,
    "unicodeFlag": "🇨🇫",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Harare",
    "country": "Zimbabwe",
    "lat": -17.8292,
    "lng": 31.0522,
    "population": 2150000,
    "unicodeFlag": "🇿🇼",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Port Moresby",
    "country": "Papua New Guinea",
    "lat": -9.4789,
    "lng": 147.1494,
    "population": 364125,
    "unicodeFlag": "🇵🇬",
    "region": "Oceania",
    "hidden": false
  },
  {
    "name": "Male",
    "country": "Maldives",
    "lat": 4.175,
    "lng": 73.5083,
    "population": 133019,
    "unicodeFlag": "🇲🇻",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Kabul",
    "country": "Afghanistan",
    "lat": 34.5328,
    "lng": 69.1658,
    "population": 3678034,
    "unicodeFlag": "🇦🇫",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Vientiane",
    "country": "Laos",
    "lat": 17.9667,
    "lng": 102.6,
    "population": 760000,
    "unicodeFlag": "🇱🇦",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Baghdad",
    "country": "Iraq",
    "lat": 33.35,
    "lng": 44.4167,
    "population": 5796000,
    "unicodeFlag": "🇮🇶",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Minsk",
    "country": "Belarus",
    "lat": 53.9022,
    "lng": 27.5618,
    "population": 2009786,
    "unicodeFlag": "🇧🇾",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Kampala",
    "country": "Uganda",
    "lat": 0.3136,
    "lng": 32.5811,
    "population": 1659600,
    "unicodeFlag": "🇺🇬",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Kuala Lumpur",
    "country": "Malaysia",
    "lat": 3.1478,
    "lng": 101.6953,
    "population": 8285000,
    "unicodeFlag": "🇲🇾",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Phnom Penh",
    "country": "Cambodia",
    "lat": 11.5696,
    "lng": 104.921,
    "population": 2129371,
    "unicodeFlag": "🇰🇭",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Cairo",
    "country": "Egypt",
    "lat": 30.0561,
    "lng": 31.2394,
    "population": 19372000,
    "unicodeFlag": "🇪🇬",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Accra",
    "country": "Ghana",
    "lat": 5.6037,
    "lng": -0.187,
    "population": 2291352,
    "unicodeFlag": "🇬🇭",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Khartoum",
    "country": "Sudan",
    "lat": 15.6031,
    "lng": 32.5265,
    "population": 7282000,
    "unicodeFlag": "🇸🇩",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Budapest",
    "country": "Hungary",
    "lat": 47.4983,
    "lng": 19.0408,
    "population": 1752286,
    "unicodeFlag": "🇭🇺",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Belgrade",
    "country": "Serbia",
    "lat": 44.8167,
    "lng": 20.4667,
    "population": 1378682,
    "unicodeFlag": "🇷🇸",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Reykjavik",
    "country": "Iceland",
    "lat": 64.1475,
    "lng": -21.935,
    "population": 128793,
    "unicodeFlag": "🇮🇸",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Kingston",
    "country": "Jamaica",
    "lat": 17.9714,
    "lng": -76.7931,
    "population": 580000,
    "unicodeFlag": "🇯🇲",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Athens",
    "country": "Greece",
    "lat": 37.9842,
    "lng": 23.7281,
    "population": 664046,
    "unicodeFlag": "🇬🇷",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Riyadh",
    "country": "Saudi Arabia",
    "lat": 24.65,
    "lng": 46.71,
    "population": 6881000,
    "unicodeFlag": "🇸🇦",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Hong Kong",
    "country": "Hong Kong",
    "lat": 22.305,
    "lng": 114.185,
    "population": 7482000,
    "unicodeFlag": "🇭🇰",
    "region": "Asia",
    "hidden": true
  },
  {
    "name": "Stockholm",
    "country": "Sweden",
    "lat": 59.3294,
    "lng": 18.0686,
    "population": 972647,
    "unicodeFlag": "🇸🇪",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Tbilisi",
    "country": "Georgia",
    "lat": 41.7225,
    "lng": 44.7925,
    "population": 1118035,
    "unicodeFlag": "🇬🇪",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Victoria",
    "country": "Seychelles",
    "lat": -4.6236,
    "lng": 55.4544,
    "population": 26450,
    "unicodeFlag": "🇸🇨",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Luanda",
    "country": "Angola",
    "lat": -8.8383,
    "lng": 13.2344,
    "population": 8417000,
    "unicodeFlag": "🇦🇴",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Fort-de-France",
    "country": "Martinique",
    "lat": 14.6104,
    "lng": -61.08,
    "population": 82502,
    "unicodeFlag": "🇲🇶",
    "region": "Americas",
    "hidden": true
  },
  {
    "name": "Thimphu",
    "country": "Bhutan",
    "lat": 27.4833,
    "lng": 89.6333,
    "population": 104000,
    "unicodeFlag": "🇧🇹",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Lisbon",
    "country": "Portugal",
    "lat": 38.7452,
    "lng": -9.1604,
    "population": 506654,
    "unicodeFlag": "🇵🇹",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Palikir",
    "country": "Micronesia (Federated States of)",
    "lat": 6.9178,
    "lng": 158.185,
    "population": 6227,
    "unicodeFlag": "🇫🇲",
    "region": "Oceania",
    "hidden": false
  },
  {
    "name": "Manama",
    "country": "Bahrain",
    "lat": 26.225,
    "lng": 50.5775,
    "population": 157474,
    "unicodeFlag": "🇧🇭",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": " Willemstad",
    "country": "Curaçao",
    "lat": 12.108,
    "lng": -68.935,
    "population": 150000,
    "unicodeFlag": "🇨🇼",
    "region": "Americas",
    "hidden": true
  },
  {
    "name": "Jerusalem",
    "country": "Israel",
    "lat": 31.7833,
    "lng": 35.2167,
    "population": 919438,
    "unicodeFlag": "🇮🇱",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Pretoria",
    "country": "South Africa",
    "lat": -25.7464,
    "lng": 28.1881,
    "population": 741651,
    "unicodeFlag": "🇿🇦",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Kingston",
    "country": "Norfolk Island",
    "lat": -29.0569,
    "lng": 167.9617,
    "population": 1748,
    "unicodeFlag": "🇳🇫",
    "region": "Oceania",
    "hidden": true
  },
  {
    "name": "Prague",
    "country": "Czech Republic",
    "lat": 50.0833,
    "lng": 14.4167,
    "population": 1335084,
    "unicodeFlag": "🇨🇿",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Port Louis",
    "country": "Mauritius",
    "lat": -20.1667,
    "lng": 57.5,
    "population": 149194,
    "unicodeFlag": "🇲🇺",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Tashkent",
    "country": "Uzbekistan",
    "lat": 41.3,
    "lng": 69.2667,
    "population": 2424100,
    "unicodeFlag": "🇺🇿",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Bucharest",
    "country": "Romania",
    "lat": 44.4,
    "lng": 26.0833,
    "population": 1883425,
    "unicodeFlag": "🇷🇴",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Honiara",
    "country": "Solomon Islands",
    "lat": -9.4333,
    "lng": 159.95,
    "population": 84520,
    "unicodeFlag": "🇸🇧",
    "region": "Oceania",
    "hidden": false
  },
  {
    "name": "Tegucigalpa",
    "country": "Honduras",
    "lat": 14.0942,
    "lng": -87.2067,
    "population": 1126534,
    "unicodeFlag": "🇭🇳",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Praia",
    "country": "Cape Verde",
    "lat": 14.9177,
    "lng": -23.5092,
    "population": 127832,
    "unicodeFlag": "🇨🇻",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Vilnius",
    "country": "Lithuania",
    "lat": 54.6833,
    "lng": 25.2833,
    "population": 574147,
    "unicodeFlag": "🇱🇹",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Port of Spain",
    "country": "Trinidad and Tobago",
    "lat": 10.6667,
    "lng": -61.5167,
    "population": 37074,
    "unicodeFlag": "🇹🇹",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Tunis",
    "country": "Tunisia",
    "lat": 36.8008,
    "lng": 10.18,
    "population": 1056247,
    "unicodeFlag": "🇹🇳",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Ankara",
    "country": "Turkey",
    "lat": 39.93,
    "lng": 32.85,
    "population": 5503985,
    "unicodeFlag": "🇹🇷",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Chisinau",
    "country": "Moldova",
    "lat": 47.0228,
    "lng": 28.8353,
    "population": 639000,
    "unicodeFlag": "🇲🇩",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Tallinn",
    "country": "Estonia",
    "lat": 59.4372,
    "lng": 24.745,
    "population": 438341,
    "unicodeFlag": "🇪🇪",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "St. John's",
    "country": "Antigua and Barbuda",
    "lat": 17.1211,
    "lng": -61.8447,
    "population": 21926,
    "unicodeFlag": "🇦🇬",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Bogota",
    "country": "Colombia",
    "lat": 4.6126,
    "lng": -74.0705,
    "population": 9464000,
    "unicodeFlag": "🇨🇴",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Quito",
    "country": "Ecuador",
    "lat": -0.2186,
    "lng": -78.5097,
    "population": 2011388,
    "unicodeFlag": "🇪🇨",
    "region": "Americas",
    "hidden": false
  },
  {
    "name": "Wellington",
    "country": "New Zealand",
    "lat": -41.2889,
    "lng": 174.7772,
    "population": 418500,
    "unicodeFlag": "🇳🇿",
    "region": "Oceania",
    "hidden": false
  },
  {
    "name": "Moscow",
    "country": "Russia",
    "lat": 55.7558,
    "lng": 37.6178,
    "population": 17125000,
    "unicodeFlag": "🇷🇺",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Oslo",
    "country": "Norway",
    "lat": 59.9111,
    "lng": 10.7528,
    "population": 693494,
    "unicodeFlag": "🇳🇴",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Dodoma",
    "country": "Tanzania (United Republic of Tanzania)",
    "lat": -6.8,
    "lng": 39.2833,
    "population": 6698000,
    "unicodeFlag": "🇹🇿",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Laayoune (El Aaiún)",
    "country": "Western Sahara",
    "lat": 26.0928,
    "lng": -10.6089,
    "population": 217732,
    "unicodeFlag": "🇪🇭",
    "region": "Africa",
    "hidden": true
  },
  {
    "name": "Dhaka",
    "country": "Bangladesh",
    "lat": 23.7289,
    "lng": 90.3944,
    "population": 15443000,
    "unicodeFlag": "🇧🇩",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Nairobi",
    "country": "Kenya",
    "lat": -1.2864,
    "lng": 36.8172,
    "population": 5545000,
    "unicodeFlag": "🇰🇪",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Paris",
    "country": "France",
    "lat": 48.8566,
    "lng": 2.3522,
    "population": 11020000,
    "unicodeFlag": "🇫🇷",
    "region": "Europe",
    "hidden": false
  },
  {
    "name": "Muscat",
    "country": "Oman",
    "lat": 23.6139,
    "lng": 58.5922,
    "population": 1421409,
    "unicodeFlag": "🇴🇲",
    "region": "Asia",
    "hidden": false
  },
  {
    "name": "Tarawa",
    "country": "Kiribati",
    "lat": 1.3382,
    "lng": 173.0176,
    "population": 28802,
    "unicodeFlag": "🇰🇮",
    "region": "Oceania",
    "hidden": false
  },
  {
    "name": "Freetown",
    "country": "Sierra Leone",
    "lat": 8.4833,
    "lng": -13.2331,
    "population": 951000,
    "unicodeFlag": "🇸🇱",
    "region": "Africa",
    "hidden": false
  },
  {
    "name": "Asmara",
    "country": "Eritrea",
    "lat": 15.3333,
    "lng": 38.9167,
    "population": 963000,
    "unicodeFlag": "🇪🇷",
    "region": "Africa",
    "hidden": false
  }
]