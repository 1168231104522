import { CallbackFunction, EventBus } from "./_eventBus";

class ModalEventBus extends EventBus {
  protected _eventName = "modal-visibility";

  showModal(name: string, additionalPayload: object = {}) {
    this.emit(this._eventName, { [name]: { show: true, additionalPayload } });
  }

  hideModal(name: string) {
    this.emit(this._eventName, { [name]: { show: false } });
  }

  subcribeToModalVisibilityChanges(cb: CallbackFunction) {
    this.subscribe(this._eventName, cb);
  }
}

export const modalEvents = new ModalEventBus();
