import { html, css, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { City, Guess } from "../types";

@customElement("mpl-fail-modal")
export class FailModal extends LitElement {
  static styles = css`
    .content-wrapper {
      text-align: center;
      color: var(--color-text);
    }
    .country {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: var(--spacing-xs);
      font-size: 1.2rem;
    }
    .city {
      font-size: 2rem;
    }
    .flag {
      font-size: 3rem;
      margin-left: var(--spacing-m);
    }
    .copy-message {
      background: rgb(31 238 10 / 22%);
      padding: var(--spacing-s);
      color: var(--color-valid);
      margin-bottom: var(--spacing-s);
      font-weight: 600;
      opacity: 0;
      transition: all 0.3s;
      -wekit-transition: all 0.3s;
      -moz-transition: all 0.3s;
    }
  `;
  open = false;
  close = () => {};
  correctCity: City;
  guessList: Guess[] = [];

  static get properties() {
    return {
      open: { type: Boolean },
      correctCity: { type: Object },
      guessList: { type: Array },
    };
  }

  render() {
    if (!this.correctCity) {
      return;
    }

    return html`<mpl-modal-dialog
      title="Uh, oh! 😔"
      .open=${this.open}
      .close=${this.close}
    >
      <div class="content-wrapper">
        Seems like it's not your day, the correct city was:<br />

        <br />
        <mpl-correct-city .correctCity=${this.correctCity}></mpl-correct-city>
        <br />
        Try again tomorrow!
      </div>
      <mpl-stats .guessList=${this.guessList}></mpl-stats
    ></mpl-modal-dialog>`;
  }
}
