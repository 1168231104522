import { zerofill } from "./utils";

export const isYesterday = (day: Date, dayBefore: Date): boolean => {
  const yesterday = new Date(day.getTime() - 24 * 60 * 60 * 1000);

  return (
    yesterday.getDate() == dayBefore.getDate() &&
    yesterday.getMonth() == dayBefore.getMonth() &&
    yesterday.getFullYear() == dayBefore.getFullYear()
  );
};

export const isToday = (someDate: Date): boolean => {
  const today = new Date();
  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  );
};

export const getFullDate = (someDate: Date): string => {
  return [
    someDate.getFullYear(),
    zerofill(someDate.getMonth() + 1, 2),
    zerofill(someDate.getDate(), 2),
  ].join("");
};
