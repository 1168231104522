import { storage } from "./utils/storage";

export const MAX_TRIES = 8;
export const CLOSE_DISTANCE = 500;

export const INTRO_MODAL = {
  title: "How to play",
  description: `Guess the capital city in ${MAX_TRIES} tries. After each incorrect guess you will get the correct city's distance from your guess.`,
  description_1:
    "Yellow circle means you've guessed the city's region (Europe, Asia, Africa, Americas and Oceania). Enabling hard mode in settings will switch the region indicator off.",
  description_2: `New day, new city to guess 🌎`,
  buttonText: "I'm ready to have fun",
};
