export type CallbackFunction = (payload: any) => void;

export class EventBus {
  private _callbacks: Record<string, CallbackFunction[]> = {};

  subscribe(eventName: string, cb: CallbackFunction) {
    this._callbacks[eventName] = [...(this._callbacks[eventName] || []), cb];
  }

  emit(eventName: string, payload: any) {
    const listeners = this._callbacks[eventName] || [];

    if (listeners.length === 0) {
      console.log(`No listeners for event "${eventName}"`);
      return;
    }

    listeners.forEach((cb) => {
      cb(payload);
    });
  }
}
