import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { Guess } from "../types";

@customElement("mpl-stats-modal")
export class WinModal extends LitElement {
  static styles = css`
    .message {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: var(--color-text);
    }
  `;
  open = false;
  close = () => {};
  guessList: Guess[] = [];

  static get properties() {
    return {
      open: { type: Boolean },
      guessList: { type: Array },
    };
  }

  render() {
    return html`<mpl-modal-dialog
      title="Statistics"
      .open=${this.open}
      .close=${this.close}
    >
      <mpl-stats
        .guessList=${this.guessList}
        .hideShareButton=${true}
      ></mpl-stats>
    </mpl-modal-dialog>`;
  }
}
