import { html, css, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit-html/directives/class-map";

import { City } from "../../types";

@customElement("mpl-autocomplete")
export class AutocompleteList extends LitElement {
  // ---------------- styles ---------------- \\

  static styles = css`
    .autocomplete {
      width: 100%;
    }
    .list {
      border: 1px solid var(--color-border);
      border-top: none;
      border-radius: 0 0 5px 5px;
      overflow: hidden;
    }
    .item {
      background: var(--color-header-background);
      padding: var(--spacing-m);
      border-bottom: 1px solid var(--color-border);
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      transition: all 0.2s;
    }
    .item:hover,
    .item.active {
      background: var(--color-background);
    }
    .item b {
      color: var(--color-text);
    }
    .item:last-child {
      border-bottom: none;
    }
    .input {
      width: 100%;
      box-sizing: border-box;
      padding: 15px;
      border-radius: 5px;
      border: 1px solid var(--color-border);
      outline: none;
      font-size: 1.2rem;
      background-color: var(--color-input-background);
      color: var(--color-text);
      margin: 0;
    }
    .input.active {
      border-radius: 5px 5px 0 0;
    }
    input:disabled {
      opacity: 0.7;
    }
    .subtext {
      font-size: 0.8rem;
      color: var(--color-subtext);
      margin-top: var(--spacing-xs);
    }
    .flag {
      font-size: 2rem;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--color-input-placeholder);
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--color-input-placeholder);
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: var(--color-input-placeholder);
    }
  `;

  // ---------------- properties ---------------- \\

  @property() onSelect: (city: City) => {};
  @property() onChange: (value: string) => {};

  list: any[];
  inputValue = "";
  isDisabled: boolean = false;
  selectedIndex: number = 0;

  static get properties() {
    return {
      inputValue: { type: String },
      list: { type: Array },
      isDisabled: { type: Boolean },
    };
  }

  // ---------------- functions ---------------- \\

  private _onInputChange(event: KeyboardEvent) {
    const value = (event.target as HTMLInputElement).value;
    this.onChange(value);
    this.inputValue = value;
  }

  private _onKeyDown(event: KeyboardEvent) {
    if (this.list.length === 0) {
      this.selectedIndex = 0;
      return;
    }

    switch (event.code) {
      case "ArrowDown":
        if (this.selectedIndex !== this.list.length - 1) {
          this.selectedIndex += 1;
        }
        break;
      case "ArrowUp":
        if (this.selectedIndex > 0) {
          this.selectedIndex -= 1;
        }
        break;
      case "Enter":
        this._onClick(this.list[this.selectedIndex]);
        break;
    }
  }

  private _onClick(item: any) {
    this.onSelect(item);
    this.inputValue = "";
    this.selectedIndex = 0;
  }

  render() {
    const isListVisible = this.list.length > 0;

    return html`<div class="autocomplete">
      <input
        placeholder="Search by city or country name..."
        class="${classMap({ input: true, active: isListVisible })}"
        .value=${this.inputValue}
        @keyup=${this._onInputChange}
        @keydown=${this._onKeyDown}
        .disabled=${this.isDisabled}
      />
      ${isListVisible
        ? html` <div class="list">
            ${this.list.map(
              (item, index) =>
                html`<div
                  class="${classMap({
                    item: true,
                    active: index === this.selectedIndex,
                  })}"
                  @click=${() => this._onClick(item)}
                >
                  <div>
                    <b>${item.name}</b>
                    <div class="subtext">${item.country}</div>
                  </div>
                  <div class="flag">${item.unicodeFlag}</div>
                </div> `
            )}
          </div>`
        : null}
    </div>`;
  }
}
