import { html, css, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { classMap } from "lit-html/directives/class-map";

@customElement("mpl-switch")
export class Switch extends LitElement {
  // ---------------- styles ---------------- \\

  static styles = css`
    .switch {
      height: 20px;
      width: 32px;
      vertical-align: middle;
      background: var(--color-border);
      border-radius: 999px;
      display: block;
      position: relative;
      cursor: pointer;
    }

    .switch.checked {
      background: var(--color-valid);
    }

    .switch.checked .knob {
      transform: translateX(calc(100% - 4px));
    }

    .knob {
      display: block;
      position: absolute;
      left: 2px;
      top: 2px;
      height: calc(100% - 4px);
      width: 50%;
      border-radius: 8px;
      background: var(--white);
      transform: translateX(0);
      transition: transform 0.3s;
    }
  `;

  static get properties() {
    return {
      checked: { type: Boolean },
    };
  }
  checked: boolean;

  render() {
    return html`<div
      class="${classMap({ switch: true, checked: this.checked })}"
    >
      <span class="knob"></span>
    </div>`;
  }
}
