import { html, css, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { classMap } from "lit-html/directives/class-map";

import { City, Guess } from "../types";
import { getCorrectCity } from "../utils/utils";

@customElement("mpl-guess-list")
export class GuessList extends LitElement {
  static styles = css`
    .guess {
      margin-bottom: var(--spacing-xxl);
    }
    .guess.small {
      margin-bottom: var(--spacing-l);
    }

    .guess:last-of-type {
      margin-bottom: 0;
    }
  `;

  list: Guess[] = [];
  correctCity: City = getCorrectCity();
  reduceGap = false;
  isGameFinished = false;

  static get properties() {
    return {
      list: { type: Array },
      reduceGap: { type: Boolean },
      isGameFinished: { type: Boolean },
    };
  }

  render() {
    return html`<div class="list-wrapper">
      ${this.list.map(
        (item, index) =>
          html`<div class="${classMap({ guess: true, small: this.reduceGap })}">
            <mpl-guess
              .index=${index + 1}
              .city=${item}
              .correctCity=${this.correctCity}
            ></mpl-guess>
          </div>`
      )}
      ${!this.isGameFinished
        ? html`<div class="${classMap({ guess: true, small: this.reduceGap })}">
            <mpl-guess
              .placeholder=${true}
              .index=${this.list.length + 1}
            ></mpl-guess>
          </div>`
        : null}
    </div>`;
  }
}
