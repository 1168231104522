import { Guess } from "../../types";

interface GameData {
  live: {
    date: Date;
    guessList: Guess[];
  };
  settings: {
    darkMode: boolean;
    unit: "kilometers" | "miles";
    hardMode: boolean;
    includeAll: boolean;
  };
  previousGames: {
    result: "win" | "fail";
    guessIndex: number;
    correctCity: string;
    timestamp: Date;
  }[];
}

const defaultData: GameData = {
  live: {
    date: new Date(),
    guessList: [],
  },
  settings: {
    darkMode: false,
    unit: "kilometers",
    hardMode: false,
    includeAll: false,
  },
  previousGames: [],
};

interface Action {
  name: string;
  payload: any;
}

type ReducerFn = (state: GameData, action: Action) => GameData;
type SubscriberFn = (state: GameData) => void;
type Subscriber = {
  fn: SubscriberFn;
  actionsList: string[];
};

class Storage {
  _keyName = "game-data";
  state: GameData;
  reducers: ReducerFn[] = [];
  subscribers: Subscriber[] = [];

  constructor() {
    this.state = this.getData();
    // Potential improvement, detect if native dark mode and set it as default
  }

  getData(): GameData {
    const data = window.localStorage.getItem(this._keyName);

    if (!data) {
      return defaultData;
    }

    return JSON.parse(data);
  }

  setData(data: GameData) {
    this.state = data;
    window.localStorage.setItem(this._keyName, JSON.stringify(data, null, 0));
  }

  dispatch(action: Action) {
    this.reducers.forEach((reducerFn) => {
      this.setData(reducerFn(this.state, action));
    });

    this.subscribers.forEach((subscriber) => {
      if (
        subscriber.actionsList.length > 0 &&
        subscriber.actionsList.indexOf(action.name) < 0
      ) {
        return;
      }

      subscriber.fn(this.state);
    });
  }

  subscribe(subscriberFn: SubscriberFn, actionsList: string[] = []) {
    this.subscribers.push({ fn: subscriberFn, actionsList });
  }

  addReducer(reducerFn: ReducerFn) {
    this.reducers.push(reducerFn);
  }
}

export const storage = new Storage();

storage.addReducer((state, action) => {
  switch (action.name) {
    case "game-finish":
      return {
        ...state,
        previousGames: [...state.previousGames, action.payload],
      };
    case "update-guesslist":
      return {
        ...state,
        live: {
          date: new Date(),
          guessList: action.payload,
        },
      };
    case "update-settings":
      return {
        ...state,
        settings: {
          ...state.settings,
          [action.payload.key]: action.payload.value,
        },
      };
    case "toggle-settings":
      const key = action.payload as keyof GameData["settings"];
      return {
        ...state,
        settings: {
          ...state.settings,
          [key]: !state.settings[key],
        },
      };
    default:
      return state;
  }
});
