import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { MAX_TRIES } from "../constants";
import { Guess } from "../types";
import { trackEvent } from "../utils/analytics";
import { isYesterday, isToday } from "../utils/date";
import { storage } from "../utils/storage";
import { getCorrectCity } from "../utils/utils";

type ShareMethod = "native-share" | "clipboard" | "none";

@customElement("mpl-stats")
export class Stats extends LitElement {
  static styles = css`
    .statistics,
    .stats-items {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .statistics {
      flex-direction: column;
    }
    .stats-items {
      width: 100%;
      margin-top: var(--spacing-l);
    }

    .stats-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: var(--spacing-s);
      margin-right: var(--spacing-s);
      background: var(--color-primary);
      color: var(--white);
      border-radius: var(--border-radius-s);
      flex: 30%;
      font-size: 1.5rem;
    }
    .stats-item:last-child {
      margin-right: 0;
    }
    .stats-item-subtext {
      font-size: 0.8rem;
      font-weight: 300;
      margin-bottom: var(--spacing-m);
    }
    .copy-message {
      background: rgb(31 238 10 / 22%);
      padding: var(--spacing-s);
      color: var(--color-valid);
      margin: var(--spacing-s) 0;
      font-weight: 600;
      opacity: 0;
      transition: all 0.3s;
      -wekit-transition: all 0.3s;
      -moz-transition: all 0.3s;
    }
    .copy-message.is-visible {
      opacity: 1;
    }
    .message {
      margin-top: var(--spacing-l);
      color: var(--color-text);
    }

    @media screen and (max-width: 500px) {
      .stats-items {
        flex-wrap: wrap;
      }
      .stats-item:nth-of-type(odd) {
        margin: 5px 5px 5px 0;
      }
      .stats-item:nth-of-type(even) {
        margin: 5px 0 5px 5px;
      }
    }
  `;
  guessList: Guess[] = [];
  hideShareButton: Boolean = false;

  static get properties() {
    return {
      guessList: { type: Array },
      hideShareButton: { type: Boolean },
      metaClickCount: { type: Number },
    };
  }

  _getGuessColor(isCorrect: Boolean, distance: number, region: string): string {
    const correctCity = getCorrectCity();

    if (isCorrect) {
      return "🟢";
    }
    const isCorrectRegion = region === correctCity.region;

    if (isCorrectRegion) {
      return `🟡 ${Math.round(distance)} km`;
    }
    return `🔴 ${Math.round(distance)} km`;
  }

  _isMobile(): boolean {
    return /iphone|ipod|android|ie|blackberry|fennec/.test(
      navigator.userAgent.toLowerCase()
    );
  }

  _shareResult(result: string): ShareMethod {
    const numberOfTries = result === "fail" ? "X" : this.guessList.length;

    const text = `mapple ${numberOfTries}/${MAX_TRIES} \n\n${this.guessList
      .map((guess) =>
        this._getGuessColor(guess.isCorrect, guess.distance, guess.region)
      )
      .join("\n")}
      \nhttps://mapplegame.com`;

    if (this._isMobile() && navigator.share) {
      navigator.share({ text });
      return "native-share";
    } else if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
      return "clipboard";
    }

    return "none";
  }

  _onShare() {
    const result = this.guessList[this.guessList.length - 1].isCorrect
      ? "win"
      : "fail";
    const method = this._shareResult(result);

    trackEvent({
      action: "share",
      category: "game",
      label: `${method}/${result}`,
    });

    if (method === "clipboard") {
      const obj = this.shadowRoot.getElementById("copyMessage");

      obj.classList.add("is-visible");
      window.setTimeout(() => {
        obj.classList.remove("is-visible");
      }, 1500);
    }
  }

  _getStats() {
    const state = storage.getData();

    const streaks = state.previousGames
      .sort(
        (a, b) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      )
      .filter(({ result }) => result === "win")
      .reduce((acc, game) => {
        const currentStreak = acc.length > 0 ? acc[acc.length - 1] : null;
        const ts = new Date(game.timestamp);

        if (!currentStreak) {
          return [[ts]];
        }

        if (isYesterday(ts, currentStreak[currentStreak.length - 1])) {
          const newAcc = [...acc];
          newAcc[acc.length - 1].push(ts);
          return newAcc;
        }

        return [...acc, [ts]];
      }, []);

    const maxStreak = streaks.reduce(
      (max, streak) => (max < streak.length ? streak.length : max),
      0
    );

    const currentStreak =
      streaks.length > 0 &&
      (isToday(
        streaks[streaks.length - 1][streaks[streaks.length - 1].length - 1]
      ) ||
        isYesterday(
          new Date(),
          streaks[streaks.length - 1][streaks[streaks.length - 1].length - 1]
        ))
        ? streaks[streaks.length - 1].length
        : 0;

    const previousGamesLength = state.previousGames.length;

    return {
      playedGames: previousGamesLength,
      winPercentage: previousGamesLength
        ? Math.round(
            (state.previousGames.filter(({ result }) => result === "win")
              .length /
              previousGamesLength) *
              100
          )
        : previousGamesLength,
      maxStreak,
      currentStreak,
    };
  }

  metaClickCount = 0;

  render() {
    const stats = this._getStats();

    return html`<div class="statistics">
        <div class="stats-items">
          <div class="stats-item">
            <div class="stats-item-subtext">Played</div>
            ${stats.playedGames}
          </div>
          <div class="stats-item">
            <div class="stats-item-subtext">Win %</div>
            ${stats.winPercentage}
          </div>
          <div class="stats-item">
            <div class="stats-item-subtext">Streak</div>
            ${stats.currentStreak}
          </div>
          <div
            class="stats-item"
            @click=${() => {
              this.metaClickCount += 1;
            }}
          >
            <div class="stats-item-subtext">Max streak</div>
            ${stats.maxStreak}
          </div>
        </div>
      </div>
      ${this.metaClickCount >= 10
        ? html`<pre>${JSON.stringify(storage, null, 2)}</pre>`
        : null}
      ${!this.hideShareButton
        ? html` <div class="message">
              Click the button below and share with your friends your path to
              the right city
            </div>
            <div id="copyMessage" class="copy-message">
              Copied to clipboard!
            </div>
            <mpl-button @click=${() => this._onShare()}>Share</mpl-button>`
        : null}`;
  }
}
