import { html, css, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

@customElement("mpl-button")
export class Button extends LitElement {
  // ---------------- styles ---------------- \\

  static styles = css`
    .button {
      font-family: "Lato", sans-serif;
      font-size: 1.2rem;
      font-weight: 500;
      width: 100%;
      background: var(--color-primary);
      padding: var(--spacing-m);
      color: var(--white);
      border: none;
      border-radius: var(--border-radius-s);
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;
    }
  `;

  render() {
    return html`<button class="button"><slot></slot></button>`;
  }
}
