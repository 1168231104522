import { html, LitElement } from "lit";
import { customElement } from "lit/decorators";
import { modalEvents } from "../utils/events/modalEventBus";

type ModalState = Record<string, { show: boolean; additionalPayload?: any }>;

@customElement("mpl-modals")
export class Modals extends LitElement {
  state: ModalState = {
    welcome: { show: false },
    win: { show: false },
    fail: { show: false },
    settings: { show: false },
    stats: { show: false },
  };

  static get properties() {
    return {
      state: { type: Object },
    };
  }

  constructor() {
    super();
    modalEvents.subcribeToModalVisibilityChanges((data) => {
      this.state = {
        ...this.state,
        ...data,
      };
    });
  }

  private _onClose(name: string) {
    return () => {
      this.state = {
        ...this.state,
        [name]: { show: false },
      };
    };
  }

  render() {
    return html`
      <mpl-welcome-modal
        .open=${this.state.welcome.show}
        .close=${this._onClose("welcome")}
      ></mpl-welcome-modal>
      <mpl-settings-modal
        .open=${this.state.settings.show}
        .close=${this._onClose("settings")}
      ></mpl-settings-modal>
      <mpl-win-modal
        .open=${this.state.win.show}
        .close=${this._onClose("win")}
        .guessList=${this.state.win.additionalPayload?.guessList || []}
        .correctCity=${this.state.win.additionalPayload?.correctCity}
      ></mpl-win-modal>
      <mpl-stats-modal
        .open=${this.state.stats.show}
        .close=${this._onClose("stats")}
        .guessList=${this.state.stats.additionalPayload?.guessList || []}
      ></mpl-stats-modal>
      <mpl-fail-modal
        .open=${this.state.fail.show}
        .close=${this._onClose("fail")}
        .guessList=${this.state.fail.additionalPayload?.guessList || []}
        .correctCity=${this.state.fail.additionalPayload?.correctCity}
      ></mpl-fail-modal>
    `;
  }
}
