interface Event {
  action: string;
  category: string;
  label: string;
}

export const trackEvent = (event: Event) => {
  const gtag = window.gtag;

  if (!gtag) {
    return;
  }

  gtag("event", event.action, {
    event_category: event.category,
    event_label: event.label,
  });
};
