import { html, css, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { City, Guess } from "../types";

import { modalEvents } from "../utils/events/modalEventBus";
import { MAX_TRIES } from "../constants";
import { trackEvent } from "../utils/analytics";
import { storage } from "../utils/storage";
import { isToday } from "../utils/date";
import { getCorrectCity } from "../utils/utils";

@customElement("mpl-game")
export class App extends LitElement {
  constructor() {
    super();
  }

  static styles = css`
    .content-item {
      margin-bottom: var(--spacing-xxl);
    }
  `;

  guessList: Guess[] = this._getGuessList();
  correctCity: City | null = getCorrectCity();

  static get properties() {
    return {
      guessList: { type: Array },
    };
  }

  private _getGuessList(): Guess[] {
    const state = storage.getData();

    if (isToday(new Date(state.live.date))) {
      return state.live.guessList || [];
    }

    return [];
  }

  private _toRad(value: number): number {
    return (value * Math.PI) / 180;
  }

  private _calculateDistance(city1: City, city2: City): number {
    var R = 6371; // km
    var dLat = this._toRad(city2.lat - city1.lat);
    var dLon = this._toRad(city2.lng - city1.lng);
    var lat1 = this._toRad(city1.lat);
    var lat2 = this._toRad(city2.lat);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;

    return d;
  }

  private _isGameFinished(): boolean {
    const totalGuesses = this.guessList.length;
    if (totalGuesses === MAX_TRIES) {
      return true;
    }

    if (totalGuesses > 0 && this.guessList[totalGuesses - 1].isCorrect) {
      return true;
    }

    return false;
  }

  private _handleWin() {
    storage.dispatch({
      name: "game-finish",
      payload: {
        result: "win",
        guessIndex: this.guessList.length,
        correctCity: this.correctCity.name,
        timestamp: new Date(),
      },
    });

    trackEvent({
      action: "win_game",
      category: "game",
      label: `${this.correctCity.name} (${this.guessList.length}/${MAX_TRIES})`,
    });

    modalEvents.showModal("win", {
      guessList: this.guessList,
      correctCity: this.correctCity,
    });
  }

  private _handleFail() {
    storage.dispatch({
      name: "game-finish",
      payload: {
        result: "fail",
        guessIndex: MAX_TRIES,
        correctCity: this.correctCity.name,
        timestamp: new Date(),
      },
    });

    trackEvent({
      action: "fail_game",
      category: "game",
      label: `${this.correctCity.name}`,
    });

    modalEvents.showModal("fail", {
      correctCity: this.correctCity,
      guessList: this.guessList,
    });
  }

  private _handleCityPick(city: City) {
    if (this._isGameFinished()) {
      return;
    }

    const isCorrect = city.name === this.correctCity?.name;

    this.guessList = [
      ...this.guessList,
      {
        city: city.name,
        distance: this._calculateDistance(city, this.correctCity),
        isCorrect,
        unicodeFlag: city.unicodeFlag,
        region: city.region,
        hidden: city.hidden,
      },
    ];

    storage.dispatch({
      name: "update-guesslist",
      payload: this.guessList,
    });

    if (isCorrect) {
      this._handleWin();
    } else {
      const maxTriesReached = this.guessList.length === MAX_TRIES;
      if (maxTriesReached) {
        this._handleFail();
      }
    }
  }

  render() {
    if (location.hostname === "localhost") {
      console.log(this.correctCity);
    }

    return html`
      <div class="content-item">
        <mpl-guess-list
          .list=${this.guessList}
          .isGameFinished=${this._isGameFinished()}
        ></mpl-guess-list>
      </div>
      <mpl-city-input
        .onPick="${(city: City) => this._handleCityPick(city)}"
        .guessList=${this.guessList}
        .isDisabled=${this._isGameFinished()}
      ></mpl-city-input>
    `;
  }
}
